import React from 'react';
import { Button } from 'antd';
import { ReactComponent as LogoQC } from '../icons/Logo.svg';
import { ReactComponent as XMark } from '../icons/ph_x-circle.svg';

const OptedIn = () => {
  return (
    <>
      <div style={{ maxWidth: '350px', maxHeight: '800px', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div className='opt-in__logo-wrapper' style={{ backgroundColor: "#FFFFFF", paddingTop: "5vh" }}>
          <LogoQC style={{ color: "#4962E2", width: "203px", height: '70px', padding: 0 }} />
        </div>

        <div className='opt-in__body' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "200px" }}>
          <h3 className='opt-in__title' style={{ fontFamily: 'Noto Sans', fontSize: '16px', fontWeight: 600 }}>
            Welcome to QClinical.
          </h3>

          <div className='opt-in__text' style={{ fontFamily: 'Noto Sans', fontSize: '16px', marginTop: '30px' }}>
            Please enter the User ID that you received during your onboarding, in the field below.
          </div>
        </div>

        <div className='opt-in__body' style={{ height: "240px", marginBottom: "5vh" }}>

          <div className='opt-in__form-item'>
            <div style={{ display: 'flex', fontFamily: 'Noto Sans', fontSize: '12px', height: '60px', border: '1px solid #FB5E5E', borderRadius: '10px', padding: '10px', alignItems: 'center', color: '#000000' }}>
              <XMark style={{ width: '40px', height: '40px', marginLeft: '10px', marginRight: '10px' }} />
              <span>This user ID is already activated with another device. Retry or contact <a href="mailto:support@qclinical.io?subject=Problem with opting in">Support</a> for help.</span>
            </div>
          </div>

          <div className='opt-in__form-item' style={{ fontFamily: 'Noto Sans', margin: 0, marginTop: '10px' }}>
            <Button
              block
              type='primary'
              disabled={true}
              style={{ height: '60px', borderRadius: '10px', fontSize: '18px' }}
            >
              Confirm User ID
            </Button>
          </div>

          <div className='opt-in__text opt-in__text--small'>
            <div style={{ display: 'flex', fontFamily: 'Noto Sans', fontSize: '12px', height: '60px', padding: '10px', alignItems: 'center', color: '#000000' }}>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default OptedIn;