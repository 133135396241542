import React from 'react';
// import { Editor } from '@tinymce/tinymce-react'; // OMITTED
import { ReactComponent as SurveyDot } from '../../icons/survey-dot.svg'
import { ReactComponent as CheckedIcon } from '../../icons/ph_check-bold.svg';

const SurveyElement = ({ block, width, selectedTextEditor, setSelectedTextEditor, tinyMCEContentStyleString, tinyMCEFontFormatsString, defaultFont, theme, editCallback }) => {

  const surveyOptionStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${width / 570 * 10}px`,
    fontFamily: block.blockProperties.surveyOptionFont?.name,
    fontStyle: block.blockProperties.surveyOptionFont?.style,
    fontWeight: block.blockProperties.surveyOptionFont?.weight,
    color: block.blockProperties.surveyOptionFontColor?.hex,
    border: block.blockProperties.surveyPreviewActive ? (
      block.blockProperties.surveyOptionActiveBorderThickness &&
        block.blockProperties.surveyOptionActiveBorderType &&
        block.blockProperties.surveyOptionActiveBorderColor
        ? `${block.blockProperties.surveyOptionActiveBorderThickness}em ${block.blockProperties.surveyOptionActiveBorderType} ${block.blockProperties.surveyOptionActiveBorderColor.hex}`
        : ''
    ) : (
      block.blockProperties.surveyOptionBorderThickness &&
        block.blockProperties.surveyOptionBorderType &&
        block.blockProperties.surveyOptionBorderColor
        ? `${block.blockProperties.surveyOptionBorderThickness}em ${block.blockProperties.surveyOptionBorderType} ${block.blockProperties.surveyOptionBorderColor.hex}`
        : ''
    ),

    borderRadius: block.blockProperties.surveyOptionBorderRadius,
    backgroundColor: block.blockProperties.surveyPreviewActive ? block.blockProperties.surveyOptionActiveBackgroundColor?.hex : block.blockProperties.surveyOptionBackgroundColor?.hex,
  };

  return (
    <div
      style={{
        fontSize: `${width / 570 * 16}px`,
        width: '100%',
        height: '100%',
        backgroundColor: block.blockProperties.surveyBackgroundColor?.hex,
        overflow: 'hidden',
        border:
          block.blockProperties.surveyBorderThickness &&
            block.blockProperties.surveyBorderType &&
            block.blockProperties.surveyBorderColor
            ? `${block.blockProperties.surveyBorderThickness}em ${block.blockProperties.surveyBorderType} ${block.blockProperties.surveyBorderColor.hex}`
            : 'none',
        padding: '1.25em'
      }}
    >
      {/* OMITTED - BEGIN */}
      {/*<div>*/}
      {/*  {selectedTextEditor && selectedTextEditor === block._id ? (*/}
      {/*    <Editor*/}
      {/*      init={{*/}
      {/*        inline: true,*/}
      {/*        // fixed_toolbar_container: '#testtt',*/}
      {/*        // height: `${item.block.size[0] * 95}`,*/}
      {/*        menubar: false,*/}
      {/*        plugins: [*/}
      {/*          'importcss code link charmap lists wordcount charmap emoticons paste lists advlist autoresize',*/}
      {/*        ],*/}
      {/*        toolbar1: 'fontselect fontsizeselect forecolor',*/}
      {/*        toolbar2:*/}
      {/*          'bold italic underline alignleft aligncenter alignright alignjustify numlist bullist',*/}
      {/*        // toolbar2: 'forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist ',*/}
      {/*        // toolbar1: 'fontselect',*/}
      {/*        // toolbar2: 'fontsizeselect',*/}
      {/*        // toolbar3: 'undo redo',*/}
      {/*        // toolbar4: 'forecolor',*/}
      {/*        // toolbar5: 'bold italic underline',*/}
      {/*        // toolbar6: 'alignleft aligncenter alignright alignjustify',*/}
      {/*        // toolbar7: 'numlist bullist',*/}
      {/*        // plugins: 'autoresize',*/}
      {/*        min_height: 95,*/}
      {/*        autoresize_bottom_margin: 0,*/}
      {/*        // toolbar: false,*/}
      {/*        statusbar: false,*/}
      {/*        contextmenu: '',*/}
      {/*        content_style: tinyMCEContentStyleString,*/}
      {/*        font_formats: tinyMCEFontFormatsString*/}
      {/*          ? tinyMCEFontFormatsString*/}
      {/*          : 'Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato Black=lato; Roboto=roboto;',*/}
      {/*        placeholder: 'Add text here',*/}
      {/*        quickbars_insert_toolbar: false,*/}
      {/*        // toolbar_mode: 'wrap',*/}
      {/*        fontsize_formats: 'small=0.75em normal=1em large=1.5em extra-large=2em',*/}
      {/*        color_map: [*/}
      {/*          ...theme.colorPalette.colors.flatMap((color, index) => [*/}
      {/*            color.hex,*/}
      {/*            color.hex,*/}
      {/*          ]),*/}
      {/*          ...theme.colorPalette.colorsBlackWhiteGrey.flatMap((color, index) => [*/}
      {/*            color.hex,*/}
      {/*            color.hex,*/}
      {/*          ]),*/}
      {/*        ],*/}
      {/*        browser_spellcheck: true,*/}
      {/*      }}*/}
      {/*      onInit={(event, editor) => {*/}
      {/*        editor.editorCommands.execCommand(*/}
      {/*          'FontName',*/}
      {/*          false,*/}
      {/*          defaultFont.name.toLowerCase().replace(' -', ''),*/}
      {/*        );*/}
      {/*        editor.contentAreaContainer.style.fontSize = '1em';*/}
      {/*        editor.setContent(block.blockProperties.surveyQuestion);*/}
      {/*        editor.focus();*/}
      {/*        editor.selection.select(editor.getBody(), true);*/}
      {/*        editor.selection.collapse(false);*/}
      {/*        editor.on('blur', (e) => {*/}
      {/*          e.stopImmediatePropagation();*/}
      {/*          setSelectedTextEditor(null);*/}
      {/*        });*/}
      {/*        editor.on('ExecCommand', (e) => {*/}
      {/*          if (e.command === 'mceApplyTextcolor') {*/}
      {/*            document.querySelector(*/}
      {/*              'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',*/}
      {/*            ).style.background = e.value;*/}
      {/*          }*/}
      {/*          if (e.command === 'mceRemoveTextcolor') {*/}
      {/*            document.querySelector(*/}
      {/*              'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',*/}
      {/*            ).style.background = '#2A3A49';*/}
      {/*          }*/}
      {/*        });*/}
      {/*        editor.formatter.formatChanged(*/}
      {/*          'forecolor',*/}
      {/*          function (isNew, args) {*/}
      {/*            if (args.node.style.color) {*/}
      {/*              document.querySelector(*/}
      {/*                'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',*/}
      {/*              ).style.background = args.node.style.color;*/}
      {/*            } else {*/}
      {/*              document.querySelector(*/}
      {/*                'body .tox .tox-split-button[aria-label="Text color"] .tox-icon.tox-tbtn__icon-wrap',*/}
      {/*              ).style.background = '#2A3A49';*/}
      {/*            }*/}
      {/*          },*/}
      {/*          true,*/}
      {/*        );*/}
      {/*        editor.on('keydown', (e) => {*/}
      {/*          e.stopPropagation();*/}
      {/*        });*/}
      {/*      }}*/}
      {/*      apiKey='j1zjwgyada24ar7u2uwpe3dxyhx6pduzftls0ex2z129yg13'*/}
      {/*      value={block.blockProperties.surveyQuestion}*/}
      {/*      onEditorChange={(value, editor) => {*/}
      {/*        editCallback(value);*/}
      {/*      }}*/}
      {/*    // onFocus={() => setSelectedBlock(item.block._id)}*/}
      {/*    // onBlur={() => setSelectedBlock(null)}*/}
      {/*    // value={blockInPreview ? blockInPreview.blockProperties.textContent : ''}*/}
      {/*    // onEditorChange={(value, editor) => handleEditorChange(value, editor)}*/}
      {/*    />*/}
      {/*  ) : (*/}
      {/*    <div*/}
      {/*      className='text-content-container'*/}
      {/*      dangerouslySetInnerHTML={{ __html: block.blockProperties.surveyQuestion }}*/}
      {/*      onDoubleClick={() => {*/}
      {/*        if (setSelectedTextEditor) {*/}
      {/*          setSelectedTextEditor(block._id);*/}
      {/*        }*/}
      {/*      }}*/}
      {/*    ></div>*/}
      {/*  )}*/}
      {/*</div>*/}
      {/* OMITTED - END */}

      {/* THIS BLOCK IS INSTEAD THE COMMENTED ONE ABOVE - BEGIN */}
      <div>
        <div
          className='text-content-container'
          dangerouslySetInnerHTML={{ __html: block.blockProperties.surveyQuestion }}
          onDoubleClick={() => {
            if (setSelectedTextEditor) {
              setSelectedTextEditor(block._id);
            }
          }}
        ></div>
      </div>
      {/* THIS BLOCK IS INSTEAD THE COMMENTED ONE ABOVE - END */}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
          marginTop: '1.875em'
        }}
      >
        {block.blockProperties.surveyOptions?.length > 0 && block.blockProperties.surveyOptions?.map((option) => {
          return (
            <div
              style={surveyOptionStyles}
            >
              <p
                style={{
                  margin: '0'
                }}
              >{option}</p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: block.blockProperties.surveyOptionFontColor?.hex?.hex
                }}
              >
                {block.blockProperties.surveyType === 'radio' && block.blockProperties.surveyPreviewActive &&
                  <SurveyDot
                    style={{
                      width: '0.5em',
                      height: '0.5em',
                    }}
                  />
                }
                {block.blockProperties.surveyType === 'checkbox' && block.blockProperties.surveyPreviewActive &&
                  <CheckedIcon
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                    }}
                  />
                }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SurveyElement;