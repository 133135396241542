import React, { useEffect, useState, useContext } from 'react';
import { useCookies } from 'react-cookie';
import Axios from 'axios';
import Loading from '../pages/qoos/Loading';
import AlertBox from '../components/elements/AlertBox';
import CountryCodesData from '../components/elements/CountryCodesData';
import { ViewerContext } from '../App';

// Pages
import KitNumber from '../pages/KitNumber';
import PhoneVerification from '../pages/PhoneVerification';
import ConfirmPhoneVerification from '../pages/ConfirmPhoneVerification';
import StartClinicalTrial from '../pages/StartClinicalTrial';


const PatientCookieOptIn = ({ server, patientToken }) => {
  const { studyName } = useContext(ViewerContext);
  const [cookies, setCookie] = useCookies('patient_token');
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('qc_phone_number') || '');
  const [phoneVerificationCode, setPhoneVerificationCode] = useState();
  const [deviceVerificationCode, setDeviceVerificationCode] = useState(localStorage.getItem('user_id') || '');
  const [phoneFormatIsValid, setPhoneFormatIsValid] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isDeviceVerified, setIsDeviceVerified] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [verificationSMSIsSent, setVerificationSMSIsSent] = useState(false);
  const [activePage, setActivePage] = useState('main');
  const [loadingUserId, setLoadingUSerId] = useState(true);
  const [loadingPhone, setLoadingPhone] = useState(true);
  const [alertVerification, setAlertVerification] = useState(false);
  const [countryCode, setCountryCode] = useState("US")

  useEffect(() => {
    checkIfSubmitButtonIsDisabled();
  }, [submitDisabled, isPhoneVerified, isDeviceVerified]);

  const onFinish = async (values) => {
    // Check phone and device verification
    if (!isPhoneVerified || !isDeviceVerified) return;

    try {
      const res = await Axios.post(
        `${server}/set-published-qoolection-cookie`,
        { patientToken },
        { withCredentials: false }
      );
      const cookie = (await res?.data?.cookie) || null;
      if (cookie) {
        setCookie('patient_token', cookie, { path: '/', maxAge: 31536000 });
        localStorage.removeItem('qc_phone_number');
        localStorage.removeItem('qc_sms_verification_code');
        localStorage.removeItem('user_id');
        window.location.reload(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendVerificationSMS = async () => {
    // Check if phoneNumber and token exist
    if (!patientToken || !phoneNumber) return;

    try {
      const { data } = await Axios.post(
        `${server}/send-verification-sms`,
        { patientToken, phoneNumber },
        { withCredentials: false }
      );
      setVerificationSMSIsSent(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyPhoneNumber = async () => {
    try {
      if (phoneNumber && phoneVerificationCode && patientToken) {
        const { data } = await Axios.post(
          `${server}/verify-phone-number`,
          { phoneNumber, phoneVerificationCode, patientToken },
          { withCredentials: false }
        );
        if (data.isValid) {
          localStorage.setItem('qc_phone_number', phoneNumber);
          localStorage.setItem('qc_sms_verification_code', phoneVerificationCode);
          setIsPhoneVerified(true);
          setVerificationSMSIsSent(false);
        } else {
          if (phoneNumber && phoneVerificationCode) {
            setAlertVerification(true);
          }
        }
        setLoadingPhone(false);
      } else {
        setLoadingPhone(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatePhoneNumber = () => {
    localStorage.removeItem('qc_sms_verification_code');
    setIsPhoneVerified(false);
    setVerificationSMSIsSent(false);
  }

  const handleVerifyDevice = async () => {
    try {
      if (deviceVerificationCode && deviceVerificationCode?.length > 5) {
        const { data } = await Axios.post(
          `${server}/verify-device`,
          { deviceVerificationCode },
          { withCredentials: false }
        );
        if (data.isValid) {
          localStorage.setItem('user_id', deviceVerificationCode);
          setIsDeviceVerified(true);
        } else {
          if (deviceVerificationCode) {
            setAlertVerification(true);
          }
        }
        setLoadingUSerId(false);
      } else {
        setLoadingUSerId(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateKitNumber = () => {
    localStorage.removeItem('user_id');
    setIsDeviceVerified(false);
  }

  // Verify on load
  useEffect(() => {
    handleVerifyPhoneNumber();
    handleVerifyDevice();
  }, []);

  const checkIfSubmitButtonIsDisabled = () => {
    isPhoneVerified ? setSubmitDisabled(false) : setSubmitDisabled(true);
    isDeviceVerified ? setSubmitDisabled(false) : setSubmitDisabled(true);
  };

  const getCountryCodesOptions = () => {
    let cCodes = [...CountryCodesData];
    cCodes = cCodes.sort((a, b) => {
      if (a.countryNameEn < b.countryNameEn) {
        return -1;
      }
      if (a.countryNameEn > b.countryNameEn) {
        return 1;
      }
      return 0;
    });

    return cCodes.map(((code, idx) => {
      return {
        key: idx,
        value: code.countryCode,
        label: `${code.flag} +${code.countryCallingCode} ${code.countryNameEn}`,
        callingCode: `+${code.countryCallingCode}`
      }
    }))
  }

  const getCountryCodeNumber = (cc) => {
    let ccObject = CountryCodesData.find(ccd => ccd.countryCode === cc);
    return `+${ccObject?.countryCallingCode}`
  }

  const verificationCodeInput = (e) => {
    const inputValue = e.target.value;
    setPhoneVerificationCode(inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
  }

  return (
    <>
      {alertVerification && <AlertBox
        title="Error"
        message={
          <>
            <p>Invalid verification code</p>
          </>
        }
        onClick={() => {
          setAlertVerification(false)
        }}
      />}

      <Loading isLoading={loadingUserId || loadingPhone} />

      {!loadingUserId && !loadingPhone &&

        <div className='opt-in__body' style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>

          {!isPhoneVerified && !isDeviceVerified &&
            <KitNumber
              deviceVerificationCode={deviceVerificationCode}
              setDeviceVerificationCode={setDeviceVerificationCode}
              handleVerifyDevice={handleVerifyDevice}
            />
          }

          {isDeviceVerified && !isPhoneVerified && !verificationSMSIsSent &&
            <PhoneVerification
              studyName={studyName}
              getCountryCodesOptions={getCountryCodesOptions}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              setPhoneNumber={setPhoneNumber}
              handleSendVerificationSMS={handleSendVerificationSMS}
            />
          }

          {isDeviceVerified && !isPhoneVerified && verificationSMSIsSent &&
            <ConfirmPhoneVerification
              studyName={studyName}
              deviceVerificationCode={deviceVerificationCode}
              setDeviceVerificationCode={setDeviceVerificationCode}
              verificationCodeInput={verificationCodeInput}
              handleVerifyPhoneNumber={handleVerifyPhoneNumber}
              setVerificationSMSIsSent={setVerificationSMSIsSent}
              handleUpdatePhoneNumber={handleUpdatePhoneNumber}
            />
          }

          {isDeviceVerified && isPhoneVerified &&
            <StartClinicalTrial
              studyName={studyName}
              onFinish={onFinish}
            />
          }


        </div>
      }
    </>)
};

export default PatientCookieOptIn;
