import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { ReactComponent as LogoQC } from '../icons/Logo.svg';
import ListBox from '../components/ListBox';
import CountryCodesData from '../components/elements/CountryCodesData';
import TermsOfService from '../published/legalContent/TermsOfService';
import PrivacyPolicy from '../published/legalContent/PrivacyPolicy';

const PhoneVerification = ({
  studyName,
  getCountryCodesOptions,
  countryCode,
  setCountryCode,
  setPhoneNumber,
  handleSendVerificationSMS
}) => {

  const [selectListCountryCode, setSelectListCountryCode] = useState(false);
  const [phoneFormatIsValid, setPhoneFormatIsValid] = useState(false);
  const [page, setPage] = useState();

  if (selectListCountryCode) {
    return (
      <div style={{ maxWidth: '350px', width: '100%', padding: '10px' }}>
        <div className='opt-in__logo-wrapper' >
          <LogoQC className='opt-in__logo' style={{ color: "#4962E2", width: "180px", height: '89px', padding: 0 }} />
        </div>
        <div className='opt-in__body'>
          <h3 className='opt-in__title' style={{ fontFamily: 'Noto Sans', fontSize: '16px' }}>
            Select country code:
          </h3>
          <ListBox data={getCountryCodesOptions()} countryCode={countryCode} setCountryCode={setCountryCode} setSelectListCountryCode={setSelectListCountryCode} />
        </div>
      </div>
    )
  }

  const getCountryCodeNumber = (cc) => {
    let ccObject = CountryCodesData.find(ccd => ccd.countryCode === cc);
    return `+${ccObject?.countryCallingCode}`
  }

  return (
    <>
      <div style={{ maxWidth: '350px', maxHeight: '800px', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div className='opt-in__logo-wrapper' style={{ backgroundColor: "#FFFFFF", paddingTop: "5vh" }}>
          <LogoQC style={{ color: "#4962E2", width: "203px", height: '70px', padding: 0 }} />
        </div>

        {!page &&
          <>
            <div className='opt-in__body' style={{ height: "200px" }}>
              <h3 className='opt-in__title' style={{ fontFamily: 'Noto Sans', fontSize: '16px', fontWeight: 600 }}>
                Thank you for confirming that you are a part of the {studyName} clinical trial.
              </h3>

              <div className='opt-in__text' style={{ fontFamily: 'Noto Sans', fontSize: '16px', marginTop: '30px' }}>
                To continue, please enter your mobile phone number below to receive clinical trial notifications, including questionnaire notifications and clinical trial news. This is a requirement for participating in this clinical trial.
              </div>
            </div>

            <div className='opt-in__body' style={{ height: "240px", marginBottom: "5vh" }}>

              <div className='opt-in__form-group' style={{ fontFamily: 'Noto Sans', margin: 0, marginTop: '10px' }}>
                <Button
                  block
                  htmlType='submit'
                  style={{ width: "100px", fontFamily: 'Noto Sans', height: '60px', borderRadius: '10px', fontWeight: 400, fontSize: '18px', textAlign: 'center', borderColor: '#d9d9d9', color: "#333333" }}
                  onClick={() => setSelectListCountryCode(true)}
                >
                  {getCountryCodeNumber(countryCode)}
                </Button>
                <Input
                  type='text'
                  placeholder=''
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!value) {
                      setPhoneFormatIsValid(false);
                      return;
                    }
                    let newValue = `${getCountryCodeNumber(countryCode) + value}`;
                    // Validate if phone number is in correct e.164 format using regex test
                    if (newValue && newValue.match(/^\+[1-9]\d{7,14}$/)) {
                      setPhoneFormatIsValid(true);
                      setPhoneNumber(newValue);
                    }
                  }}
                  style={{ fontFamily: 'Noto Sans', height: '60px', borderRadius: '10px', fontSize: '18px', textAlign: 'center' }}
                />
              </div>

              <div className='opt-in__form-item' style={{ fontFamily: 'Noto Sans', margin: 0, marginTop: '10px' }}>
                <Button
                  block
                  disabled={!phoneFormatIsValid}
                  type='primary'
                  onClick={handleSendVerificationSMS}
                  style={{ height: '60px', borderRadius: '10px', fontSize: '18px' }}
                >
                  Send Verification Code
                </Button>
              </div>

              <div className='opt-in__form-item'>
                <div style={{ display: 'flex', fontFamily: 'Noto Sans', fontSize: '12px', height: '60px', alignItems: 'center', color: '#333333', marginTop: '10px' }}>
                  <span>By entering your phone number, you agree to receive text messages from QClinical. Message frequency varies. Message and data rates may apply. View our <span style={{ color: '#4962E2' }} onClick={() => setPage('privacyPolicy')}>Privacy Policy</span> and <span style={{ color: '#4962E2' }} onClick={() => setPage('termsOfService')}>Terms of Service</span>.</span>
                </div>
              </div>

            </div>
          </>
        }

        {page === 'privacyPolicy' &&
          <div style={{ overflow: 'hidden', overflowY: 'scroll', flexGrow: 1, marginTop: '10px', marginBottom: '10px' }}>
            <PrivacyPolicy setPage={setPage} />
          </div>
        }

        {page === 'termsOfService' &&
          <div style={{ overflow: 'hidden', overflowY: 'scroll', flexGrow: 1, marginTop: '10px', marginBottom: '10px' }}>
            <TermsOfService setPage={setPage} />
          </div>
        }

        {(page === 'privacyPolicy' || page === 'termsOfService') &&
          <div className='opt-in__form-item' style={{ fontFamily: 'Noto Sans', margin: 0, marginTop: '10px' }}>
            <Button
              block
              type='primary'
              onClick={() => setPage(null)}
              style={{ height: '40px', borderRadius: '10px', fontSize: '18px', marginBottom: '20px' }}
            >
              Close
            </Button>
          </div>
        }
      </div>
    </>
  )

}

export default PhoneVerification;