import React, { useEffect, useRef, useState } from 'react';
import { Player, BigPlayButton, ControlBar } from 'video-react';
const MediaElement = ({ block, width, muteVideo }) => {
  const [initialPlay, setInitialPlay] = useState(true);

  const videoRef = useRef();

  if (videoRef && videoRef.current) {
    videoRef.current.actions.handleEnd = () => {
      videoRef.current.load();
      setInitialPlay(false);
    };
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = block.blockProperties.volume;
    }
  }, [block.blockProperties.volume]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      setInitialPlay(true);
    }
  }, [block.blockProperties.autoplay]);

  // console.log('block: ', block);

  return (
    <div
      style={{
        fontSize: `${(width / 570) * 16}px`,
        height: '100%',
        backgroundColor: block.blockProperties.mediaBackgroundColor?.hex,
        padding: `${(width / 570) * block.blockProperties.mediaPadding}px`,
        border:
          block.blockProperties.mediaBorderThickness &&
          block.blockProperties.mediaBorderType &&
          block.blockProperties.mediaBorderColor
            ? `${block.blockProperties.mediaBorderThickness}em ${block.blockProperties.mediaBorderType} ${block.blockProperties.mediaBorderColor.hex}`
            : 'none',
      }}
    >
      {block.blockProperties.mediaType === 'image' && (
        <div
          style={{
            height: '100%',
            backgroundImage: block.blockProperties.mediaImageSrc
              ? `url(${block.blockProperties.mediaImageSrc})`
              : '',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            filter: `opacity(${block.blockProperties.mediaImageIntensity}) brightness(${block.blockProperties.mediaImageBrightness}) contrast(${block.blockProperties.mediaImageContrast}) saturate(${block.blockProperties.mediaImageSaturate})`,
            clipPath: block.blockProperties.mediaImageClipPath,
          }}
        ></div>
      )}
      {block.blockProperties.mediaType === 'video' && (
        <div className='content'>
          {/*<video
            ref={videoRef}
            className='video'
            autoPlay={block.blockProperties.autoplay}
            loop={block.blockProperties.loop}
            muted={block.blockProperties.muted || muteVideo}
            controls
          >
            <source src={block.blockProperties.mediaVideoSrc} />
    </video>*/}
          <div className='video'>
            <Player
              ref={videoRef}
              autoPlay={block.blockProperties.autoplay && initialPlay}
              loop={block.blockProperties.loop}
              muted={block.blockProperties.muted}
            >
              <source
                src={
                  block.blockProperties.mediaVideoSrc
                    ? block.blockProperties.mediaVideoSrc
                    : ''
                }
              />
              <BigPlayButton position='center' />
              <ControlBar disableCompletely={true} />
            </Player>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaElement;
