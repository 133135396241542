import React, { useEffect, useState } from 'react';

const Loading = ({
  isLoading
}) => {
  if (isLoading) {
    return (
      <div style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        textAlign: "center",
        backgroundColor: "#D5DFE8",
        zIndex: 2001
      }}>
        <div style={{textAlign: "center", marginTop: "8rem"}}>
          <p style={{marginTop: "2rem"}}>Loading...</p>
          <img src="/logo192.png" style={{marginTop: "3rem", width: "60px", height: "60px"}} className="loading-pulse" />
        </div>
      </div>
    )
  }
}

export default Loading;