import React, { useEffect, useLayoutEffect, useState, useContext } from 'react';
// import { useParams } from 'react-router-dom'; // OMITTED
import Axios from 'axios';
// import { calculateHF } from '../../utils/calculateHF';
import { calculateHF } from './utils/calculateHF';
// import QooHeader from '../qoos/QooHeader';
import QooHeader from './pages/qoos/QooHeader';
// import QooFooter from '../qoos/QooFooter';
import QooFooter from './pages/qoos/QooFooter';
// import QooBlocksLayout from '../qoos/QooBlocksLayout';
import QooBlocksLayout from './pages/qoos/QooBlocksLayout';
// import viewTypes from '../../params/viewTypes';
import viewTypes from './params/viewTypes';
// import PublishedSideBarMenu from '../../components/elements/PublishedSideBarMenu';
import PublishedSideBarMenu from './components/elements/PublishedSideBarMenu';
import _ from 'lodash';
// import publishedMenuItemsTypes from '../../params/publishedMenuItemsTypes';
import publishedMenuItemsTypes from './params/publishedMenuItemsTypes';
// import { openUrlInNewTab } from '../../utils/common';
import { openUrlInNewTab } from './utils/common';
import './App.scss';
import { ViewerContext } from './App';

import Loading from './pages/qoos/Loading';
import AlertBox from './components/elements/AlertBox';

const Viewer = ({ server, patientToken }) => {
  const { statusOnline } = useContext(ViewerContext);
  const [statusOnlineDelayed, setStatusOnlineDelayed] = useState(false);
  const [offline, setOffline] = useState(!statusOnline)

  useEffect(() => {
    if (statusOnline) {
      setStatusOnlineDelayed(true)
      setTimeout(() => {
        setStatusOnlineDelayed(false)
      }, 2000)
    } else {
      setOffline(true)
    }
  }, [statusOnline]);

  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  const [loading, setLoading] = useState(true)
  const [qooDimensions, setQooDimensions] = useState({});
  const [qooInPreview, setQooInPreview] = useState(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [qoolectionInPreview, setQoolectionInPreview] = useState(null);
  const [qooInPreviewIndex, setQooInPreviewIndex] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [publishedQoolection, setPublishedQoolection] = useState(null);
  const [refetchQoolection, setRefetchQoolection] = useState();
  const [qooInPreviewIndexRefetch, setQooInPreviewIndexRefetch] = useState()
  const minSwipeDistance = 50;
  let swipeDisabled = false;

  useLayoutEffect(() => {
    // console.log('layoutEffect');
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    // if (windowSize.innerHeight / windowSize.innerWidth < 2.2) {
    // console.log('windowSize.innerHeight: ', windowSize.innerHeight);

    // Osnovna duzina stranice jednog kvadrata je maksimalna sirina / 12
    let gridRowHeight = windowSize.innerWidth / 12;

    // Ako je stranica kvadrata * 22 veca od visine ekrana, nova velicina jedne stranice je visina / 22
    if ((windowSize.innerWidth / 12) * 22.6 > window.innerHeight) {
      gridRowHeight = window.innerHeight / 22.6;
    }

    setQooDimensions({
      // height: 0.85 * windowSize.innerHeight,
      // width: (0.85 * windowSize.innerHeight) / 1.88,
      // height: 1 * (gridRowHeight * 22),
      // prosledjujem duzinu stranice kvadrata
      gridRowHeight: gridRowHeight,
      // prosledjujem sirinu ekrana koja je visina / 1.88
      // width: (gridRowHeight * 22) / 1.88,
      width: gridRowHeight * 12,
    });
    // }
    // else {
    //   console.log('windowSize.innerWidth: ', windowSize.innerWidth);
    //   setQooDimensions({
    //     height: 1.88 * windowSize.innerWidth,
    //     width: windowSize.innerWidth,
    //   });
    // }
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  // useEffect(() => {
  //   const qoolectionToken = '7391df8e-2348-4bda-aae7-36e0b4b7529e';

  //   console.log('**** qoolectionToken : ', qoolectionToken);

  //   if (qoolectionToken) {
  //     getPublishedQoolection();
  //   }
  // }, []);

  // On the first load check initial qoo for patient

  const getPublishedQoolection = async () => {
    try {
      const res = await Axios.get(
        `${server}/published-qoolection/${patientToken}`
      );
      const qoolection = res.data;
      if (qoolection) {
        setPublishedQoolection(qoolection);
        if (qoolection.study?.name) {
          document.title = "QClinical | " + qoolection.study.name;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPublishedQoolection();
  }, [refetchQoolection])

  useEffect(() => {
    if (publishedQoolection) {
      const startingQooForConsumer = publishedQoolection.startingQooForConsumer;
      if (startingQooForConsumer && startingQooForConsumer.length > 0) {
        const patientQoo = startingQooForConsumer.find(q => q.patientToken == patientToken);
        if (patientQoo && loading) {
          const qooIndexFromLocalStorage = localStorage.getItem('qc_start_qoo');
          const qooIndex = publishedQoolection.qoos.findIndex(q => q._id === ((offline && qooIndexFromLocalStorage) ? qooIndexFromLocalStorage : patientQoo.startingQoo));
          setQooInPreviewIndex(qooIndex > 0 ? qooIndex : qooInPreviewIndex > 0 ? qooInPreviewIndex : 0);
          if (!offline) {
            localStorage.setItem('qc_start_qoo', publishedQoolection.qoos[qooIndex > 0 ? qooIndex : qooInPreviewIndex > 0 ? qooInPreviewIndex : 0]._id);
          }
        }
      }
      setQooInPreviewIndexRefetch(qr => !qr)
    }
  }, [publishedQoolection])

  useEffect(() => {
    if (publishedQoolection && qooInPreviewIndex >= 0) {
      setQoolectionInPreview(publishedQoolection);
      setQooInPreview(publishedQoolection.qoos[qooInPreviewIndex]);

      if (document.fonts.status === 'loaded') {
        setTimeout(() => setLoading(false), 2000);
      }
    }
  }, [qooInPreviewIndexRefetch, document.fonts.status]);

  let gridAlign, headerHeight, footerHeight;
  if (qooInPreview && qooInPreview.theme) {
    ({ gridAlign, headerHeight, footerHeight } = calculateHF(
      qooInPreview?.noHeaderFooter,
      {
        ...qooInPreview?.theme.headerFooter,
        header: {
          ...qooInPreview?.theme.headerFooter.header,
          noHeader: !qooInPreview.showHeader,
        },
        footer: {
          ...qooInPreview?.theme.headerFooter.footer,
          noISI: !qooInPreview.showISI,
          noFooter: !qooInPreview.showFooter,
          shownISI:
            qooInPreview.ISISize === 'S'
              ? 'title-only'
              : qooInPreview.ISISize === 'M'
                ? 'less-text'
                : qooInPreview.ISISize === 'L'
                  ? 'more-text'
                  : qooInPreview?.theme.headerFooter.footer.shownISI,
        },
      }
    ));
  }

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      if (
        isLeftSwipe &&
        qooInPreviewIndex < qoolectionInPreview.qoos.length - 1
      ) {
        // console.log("swipe", isLeftSwipe ? "left" : "right");
        // add your conditional logic here
        const newIndex = qooInPreviewIndex + 1;
        setQooInPreview(qoolectionInPreview.qoos[newIndex]);
        setQooInPreviewIndex(newIndex);
      }
    if (isRightSwipe && qooInPreviewIndex > 0) {
      const newIndex = qooInPreviewIndex - 1;
      setQooInPreview(qoolectionInPreview.qoos[newIndex]);
      setQooInPreviewIndex(newIndex);
    }
  };

  const getQooIndexByQooId = (qooId, qoolectionInPreview) => {
    const qoos = _.get(qoolectionInPreview, 'qoos', []);

    if (qoos.length < 1) {
      return 0;
    }

    return _.findIndex(qoos, (qoo) => qoo._id === qooId);
  };

  const onMenuClick = () => {
    setIsMenuOpened(true);
  };

  const onMenuItemClick = (menuItem) => {
    const menuItemType = _.get(menuItem, 'linkTo', '');
    const menuItemReference = _.get(menuItem, 'link', '');

    if (!(menuItemType && menuItemReference)) {
      // Invalid menu item data. Just close the menu.
      setIsMenuOpened(false);
      return false;
    }

    switch (menuItemType) {
      case publishedMenuItemsTypes.qoo.key:
        const qooIndex = getQooIndexByQooId(
          menuItemReference,
          qoolectionInPreview
        );

        setQooInPreview(qoolectionInPreview.qoos[qooIndex]);
        setQooInPreviewIndex(qooIndex);
        setIsMenuOpened(false);

        break;

      case publishedMenuItemsTypes.webLink.key:
        setIsMenuOpened(false);
        openUrlInNewTab(menuItemReference, 350);

        break;

      // TODO: handle case with menu item type of 'endnote' (currently called 'footnotes').

      default:
        setIsMenuOpened(false);

        break;
    }
  };

  const onMenuCloseClick = () => {
    setIsMenuOpened(false);
  };

  const onButtonClick = (qooId) => {
    const qooIndex = getQooIndexByQooId(qooId, qoolectionInPreview);

    setQooInPreview(qoolectionInPreview.qoos[qooIndex]);
    setQooInPreviewIndex(qooIndex);
  };

  swipeDisabled =
    isMenuOpened || _.get(qoolectionInPreview, 'publishedSwipeDisabled', false);

  // if(!qooInPreview) {
  //   return (
  //     <p style={{textAlign: "center", marginTop: "3rem"}}>Loading, please wait...</p>

  //   )
  // }

  return (
    <div
      onTouchStart={swipeDisabled ? null : onTouchStart}
      onTouchMove={swipeDisabled ? null : onTouchMove}
      onTouchEnd={swipeDisabled ? null : onTouchEnd}
      className='published-qoolection'
    >
      <Loading isLoading={loading} />
      {offline && <AlertBox
        title="You're offline"
        message={
          <>
            <p>It appears that you do not currently have internet access. Please check your connection.</p>
            <p>We will store your response and try to automatically forward it to our server as soon as your internet connection is reestablished.</p>
          </>
        }
        onClick={() => {
          setOffline(false)
        }}
      />}

      {/* <PatientCookieOptIn /> */}
      {qooDimensions && qooInPreview && (
        <div
          style={{
            position: 'relative',
            width: window.innerWidth,
            height: window.innerHeight,
            display: 'flex',
            alignItems: gridAlign ? gridAlign : 'center',
            backgroundColor: qooInPreview?.qooBackgroundImage
              ? ''
              : qooInPreview?.qooBackgroundColor
                ? qooInPreview?.qooBackgroundColor?.hex
                : qooInPreview.theme.colorAssignment.qooBackground
                  ? qooInPreview.theme.colorAssignment.qooBackground.hex
                  : '#fff',
            backgroundImage: qooInPreview.qooBackgroundImage
              ? `url(${qooInPreview?.qooBackgroundImage})`
              : '',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            border:
              qooInPreview.qooBorderColor &&
                qooInPreview.qooBorderThickness &&
                qooInPreview.qooBorderType
                ? `${qooInPreview.qooBorderThickness}em ${qooInPreview.qooBorderType} ${qooInPreview.qooBorderColor.hex}`
                : '1px solid #D5DFE8',
            overflow: 'hidden',
            justifyContent: 'center',
          }}
        >

          {!statusOnline &&
            <div style={{ position: 'absolute', zIndex: 9999, width: '100%', backgroundColor: "red", color: "white", textAlign: "center", fontSize: '0.75rem' }}>
              You are working offline
            </div>
          }
          {statusOnlineDelayed &&
            <div style={{ position: 'absolute', zIndex: 9999, width: '100%', backgroundColor: "green", color: "white", textAlign: "center", fontSize: '0.75rem' }}>
              You are online
            </div>
          }
          {qoolectionInPreview &&
            qooInPreview.blocksLayout &&
            qooInPreview.blocksLayout.length > 0 && (
              <QooBlocksLayout
                qooInPreview={qooInPreview}
                layout={qooInPreview.blocksLayout}
                gridWidth={qooDimensions.gridRowHeight * 12}
                // gridRowHeight={qooDimensions.width / 12}
                gridRowHeight={qooDimensions.gridRowHeight}
                viewType={viewTypes.published.key}
                onButtonClickCallback={onButtonClick}
                qoolectionCookie={patientToken}
                qoolectionId={qoolectionInPreview._id}
                setRefetchQoolection={setRefetchQoolection}
                setLoading={setLoading}
              />
            )}
          {qooInPreview.showHeader &&
            qooInPreview.theme.headerFooter &&
            !qooInPreview.theme.headerFooter.header.noHeader && (
              <QooHeader
                headerHeight={headerHeight}
                header={qooInPreview.theme.headerFooter.header}
                // gridRowHeight={qooDimensions.width / 12}
                gridRowHeight={qooDimensions.gridRowHeight}
                gridWidth={qooDimensions.width}
                viewType={viewTypes.published.key}
                onMenuClickCallback={onMenuClick}
              />
            )}
          {(qooInPreview.showISI || qooInPreview.showFooter) &&
            (qooInPreview.theme?.headerFooter && !qooInPreview.theme?.headerFooter?.footer?.noISI || !qooInPreview.theme?.headerFooter?.footer?.noFooter) && (
              // !qooInPreview.theme.headerFooter.footer.noFooter &&
              <QooFooter
                footerHeight={footerHeight}
                footer={qooInPreview.theme.headerFooter.footer}
                // gridRowHeight={qooDimensions.width / 12}
                gridRowHeight={qooDimensions.gridRowHeight}
                gridWidth={qooDimensions.width}
                dontShowISI={!qooInPreview.showISI}
                dontShowFooter={!qooInPreview.showFooter}
                ISISizeInQoo={qooInPreview.ISISize}
              />
            )}
          {qooInPreview.showHeader &&
            qooInPreview.theme.headerFooter &&
            !qooInPreview.theme.headerFooter.header.noHeader && (
              <PublishedSideBarMenu
                qoolectionInPreview={qoolectionInPreview}
                qooInPreview={qooInPreview}
                onMenuItemClick={onMenuItemClick}
                onMenuCloseClick={onMenuCloseClick}
                isMenuOpened={isMenuOpened}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default Viewer;
