import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';

const DeveloperCookie = () => {
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies('qoolection_cookie');
  const [myCookie, setMyCookie] = useState(cookie['qoolection_cookie'])

  const d = new Date();
  const expires = d.setDate(d.getDate() + 7);

  return (
    <div style={{ padding: "20px", width: "100vw", height: "100vh" }}>
      <h3>Developer cookie</h3>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", rowGap: "10px", columnGap: "10px" }}>
        <input type="text" id="qoolection_cookie" name="qoolection_cookie" defaultValue={myCookie} onChange={(e) => setMyCookie(e.target.value)} style={{width: "60vw", alignSelf: "center", textAlign: "center"}}/>
        <button
          type="button"
          style={{
            width: "30vw",
            height: "5vh",
            margin: "10px",
            backgroundColor: "#eeeeee",
            border: "1px solid black",
            alignSelf: "center",
            fontSize: "18px",
          }}
          onClick={() => {
            setCookie('qoolection_cookie', myCookie, { path: '/', expires: new Date(expires)}); 
          }}
        >
          Set
        </button>
        <button
          type="button"
          style={{
            width: "30vw",
            height: "5vh",
            margin: "10px",
            backgroundColor: "#eeeeee",
            border: "1px solid black",
            alignSelf: "center",
            fontSize: "18px",
          }}
          onClick={() => {
            removeCookie('qoolection_cookie', { path: '/' });
            setMyCookie(cookie['qoolection_cookie'])
            document.getElementById("qoolection_cookie").value = "";
          }}
        >
          Remove
        </button>
        <button
          type="button"
          style={{
            width: "50vw",
            height: "5vh",
            marginTop: "50px",
            backgroundColor: "#eeeeee",
            border: "1px solid black",
            alignSelf: "center",
            fontSize: "18px",
          }}
          onClick={() => {
            navigate('/developer')
          }}
        >
          Developer dashboard
        </button>
      </div>
    </div>
  )
}

export default DeveloperCookie;