import React from 'react';
import { useEffect } from 'react';

function PrivacyPolicy({ setPage }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <p>
        <strong>Privacy Policy</strong>
      </p>
      <p>Last updated: March 2, 2023</p>
      <p>
        <strong>1. Introduction</strong>
      </p>
      <p>
        This Privacy Policy describes the types of information gathered by Qoolo, Inc. (“Qoolo,”
        “us” or “we”) from users (“you”) in the process of providing Qoolo.com, Qoolo.io, Qoolo.app,
        QClinical.io, and QClinical.app (each a “Site” and together the “Sites”), and the associated
        data, services, information, tools, functionality, updates and similar materials
        (collectively, the “Service”), as well as how we use it, with whom the information may be
        shared, what choices are available to you regarding collection, use and distribution of
        information and our efforts to protect the information you provide to us through the
        Service.
      </p>
      <p>
        By accessing or using the Service, you hereby consent to allow us to process information in
        accordance with this Privacy Policy. Please also refer to the subscription agreement
        executed by and between you and us, if any (“Subscription Agreement”), or our{' '}
        <span className='legal-link' onClick={() => setPage('termsOfService')}>
          Terms of Service
        </span>{' '}
        (“Terms of Service”) which is incorporated as if fully recited herein. Terms defined in the
        Subscription Agreement or Terms of Service that are not defined herein shall have the same
        definition as in the Subscription Agreement or Terms of Service, as applicable.
      </p>
      <p>
        We may revise or update this Privacy Policy by posting an amended version through the
        Service and making you aware of the revisions, which may be through posting to the Service
        or otherwise. Your continued access to or use of the Service following our notice of changes
        to this Privacy Policy (or other acceptance method) means you accept such changes. Please
        refer to the “Last updated” date above to see when this Privacy Policy was last updated.
      </p>
      <p>
        <strong>2. Territoriality</strong>
      </p>
      <p>
        Regardless of where our servers are located, your personal data may be processed by us in
        the United States of America (“U.S.”), where data protection and privacy regulations may or
        may not be the same level of protection as in other parts of the world. In all instances in
        this Privacy Policy, “our servers” means servers that we own or on which the Service is
        hosted, or which are otherwise utilized by the Service. BY USING THE SERVICE, YOU
        UNEQUIVOCALLY AND UNAMBIGUOUSLY CONSENT TO THE COLLECTION AND PROCESSING IN THE U.S. OF ANY
        INFORMATION COLLECTED OR OBTAINED BY US THROUGH VOLUNTARY SUBMISSIONS, AND, TO THE EXTENT
        POSSIBLE, THAT U.S. LAW GOVERNS ANY SUCH COLLECTION AND PROCESSING.
      </p>
      <p>
        <strong>3. Who Collects Your Information On Our Service?</strong>
      </p>
      <p>
        We collect information from you on the Service, including certain information that can
        identify you as a person (“Personally Identifiable Information” or “PII.”), and we are
        responsible for protection of your PII.
      </p>
      <p>
        <strong>4. What Information Do We Collect?</strong>
      </p>
      <p>
        4.1. <u>Personally Identifiable Information</u>. We may collect PII from you when you
        register for the Service, when you create or update your account, when you use the Service,
        from third parties, when you communicate or interact with us, or when you otherwise submit
        such information. You may choose not to provide your PII, but then you might not be able to
        take advantage of some of the features of our Service. The types of PII we may collect and
        save include:
      </p>
      <p>
        · Contact and account registration information such as name, address, email address, and
        telephone number;
      </p>
      <p>
        · Technical information collected in our logs, such as standard web log entries that contain
        your IP address, keystrokes, clicks and actions while using the Service, page URLs, the
        pages and features accessed most frequently, time spent on a page, how pages and features
        are used, previous page and referring page URLs, search terms entered, and similar analytics
        about use of the Service;
      </p>
      <p>
        · Device information such as mobile phone provider associated with the device you are using
        to access the Service, your device’s unique identifier, the type of device and its operating
        system, and screen resolution of the device;
      </p>
      <p>· Location-based information;</p>
      <p>· Information you provide such as feedback, comments or other messages; and</p>
      <p>
        · Employment information, such as the name of your employer, role at your employer, work
        history and work information, and your employer’s business name, URL, location and type of
        industry.
      </p>
      <p>
        4.2. <u>Anonymous Information</u>. We may also collect anonymous, non-identifying and
        aggregate information such as the type of browser you are using, device type, the operating
        system you are using, and the domain name of your Internet service provider. We may create
        anonymous or de-identified information from PII. Information collected or created pursuant
        to this section will not be considered PII and not subject to the terms and conditions of
        this Privacy Policy.
      </p>
      <p>
        4.3. <u>User Content</u>. As part of the functionality of the Service, users may upload
        suggestions, information, ideas, comments, causes, promotions, documents, questions, notes,
        plans, drawings, proposals, graphics, text, information, links, profiles, audio, photos,
        software, music, sounds, video, links to third party video, comments, messages or tags, or
        similar materials (collectively, “User Content”) to the Service. We collect User Content
        only if, and to the extent that, you upload it to the Service. User Content shall only
        contain your PII to the extent that you, or another User authorized by you, include such PII
        in such User Content.
      </p>
      <p>
        4.4. <u>Health Information</u>. As part of the functionality of QClinical.app, certain users
        who are clinical trial participants may provide us information related to such user’s
        participation in such clinical trial (“Health Information”). You may not upload Health
        Information concerning another individual. Health Information is collected pursuant to our
        relationships with clinical trial sponsors and/or clinical trial sites and are subject to
        the terms and conditions of our agreements with such covered entities, such as business
        associate agreements, or are subject to other laws and regulations applicable to health
        data. Our practices with respect to Health Information, including any such Health
        Information that consists of or includes protected health information under the Health
        Insurance Portability and Accountability Act of 1996, as amended by the Health Information
        Technology for Economic and Clinical Health (HITECH) Act, enacted as part of the American
        Recovery and Reinvestment Act of 2009, are governed by and described in the applicable
        clinical trial sponsor’s privacy policy or notice of privacy practices. Please review the
        applicable clinical trial sponsor’s policies and notices or contact them directly for more
        information on such practices.
      </p>
      <p>
        4.5. <u>Third Party Information</u>. The Service may be integrated with third party
        platforms, such as CRM system providers (collectively, the “Platform Providers”). If you
        choose to interact with the Platform Providers, the Platforms Providers may make additional
        PII about you available to us. We may receive additional PII from third party credential
        verification service providers for purposes of verifying your credentials. We may receive
        additional PII from third parties that offer public resources and databases on our purported
        users, such as professional or industry organizations that offer publicly published lists of
        physicians. PII received from third parties is referred to collectively as “Third Party
        Information”. The collection of Third Party Information by the applicable third party is
        governed by your agreements with, or policies of, such third party and is outside the scope
        of this Privacy Policy.
      </p>
      <p>
        4.6. <u>Financial Information</u>. We do not collect financial information from you on the
        Service. However, we use third party service providers to process payments for the Service
        and if you are an account administrator responsible for making payments for the Service,
        they do collect financial information, such as credit card number, name, CVV code, date of
        expiration, or bank account number or other information, from you on the Service.
      </p>
      <p>
        4.7. <u>Device Permissions</u>. The Service may make phone calls, send text messages and
        send other messages via other messaging applications, if you permit such access in order to
        use certain features. The Service may also access the internet or use your mobile data plan
        in order to use certain features of the Service. The Service may access your device to send
        you push notifications.
      </p>
      <p>
        <strong>5. Why Is My Information Being Collected?</strong>
      </p>
      <p>
        We need to collect your PII so that we can respond to your requests for information and
        demonstrations or to be added to our emailing lists, and to process your requests for access
        to our Service. We also collect aggregate information to help us better design the Site and
        Service. We collect log information for monitoring purposes to help us to diagnose problems
        with our servers, administer the Site and Service, calculate usage levels, and otherwise
        provide services to you.
      </p>
      <p>
        User Content is collected for purposes of providing certain functionalities of the Service.
      </p>
      <p>
        Third Party Information is collected for purposes of user verification and providing certain
        functionalities of the Service.
      </p>
      <p>
        <strong>6. How Do We Use the Information We Collect?</strong>
      </p>
      <p>
        6.1. We use the PII that we collect for the purposes of providing the Service including:
      </p>
      <p>
        · <u>Responding To Your Inquiries and Fulfilling Your Requests</u>. We may use your PII to
        respond to your inquiries about the Service and to fulfill your requests for information.
      </p>
      <p>
        · <u>Creating and Maintaining Your User Account</u>. We use your PII to create and maintain
        an account for you to allow you to access and/or use the Service.
      </p>
      <p>
        · <u>Communicating With You About Our Service</u>. We may use your PII to send you
        information about new features of the Service and other items that may be of interest to
        you.
      </p>
      <p>
        · <u>Sending Administrative and Promotional Communications</u>. We may use your PII to send
        you emails, text messages, and other communications to: (a) confirm your account and your
        other PII, (b) provide you with information regarding the Service, (c) inform you of changes
        to this Privacy Policy, our Terms of Service, or our other policies; or (d) provide you with
        information on our other services and products, or promotions related to the Service or our
        other services and products.
      </p>
      <p>
        · <u>User Content</u>. We may use your User Content to provide certain functionalities of
        the Service, including providing such User Content to other users of the Service as directed
        by you.
      </p>
      <p>
        · <u>Third Party Information</u>. We may use Third Party Information to verify your user
        credentials and provide certain functionalities of the Service.
      </p>
      <p>
        6.2. We may use anonymous information that we collect to improve the design and content of
        our Service, and to enable us to personalize your Service experience. We also may use this
        information in the aggregate to analyze how the Service is used, analyze industry trends, as
        well as to offer you programs or services.
      </p>
      <p>
        <strong>7. Do We Share Your Personally Identifiable Information?</strong>
      </p>
      <p>
        In general, we will not share your PII except: (a) for the purposes for which you provided
        it; (b) with your consent, or as you direct; (c) as may be required by law or as we think
        necessary to protect our organization or others from injury (e.g., in response to a court
        order or subpoena, in response to a law enforcement agency request, or when we believe that
        someone is causing, or is about to cause, injury to or interference with the rights or
        property of another); or (d) on a confidential basis with persons or organizations with whom
        we contract to carry out internal site operations, which may include for example, analytical
        services, or as necessary to render the Service. With your knowledge and consent, we may
        share your PII with our business partners, such as our marketing partners and event
        co-hosts. We may also share aggregate information with others, including affiliated and
        non-affiliated organizations. Finally, we may transfer your PII to our successor-in-interest
        in the event of an acquisition, sale, merger or bankruptcy.
      </p>
      <p>We may share your User Content with other users of the Service as directed by you.</p>
      <p>
        We may share anonymous information with others, including affiliated and non-affiliated
        organizations.
      </p>
      <p>
        We may share Third Party Information with Platform Providers to the extent necessary to
        facilitate your use of the applicable platform.
      </p>
      <p>
        We may transfer your PII to a third party, or our successor-in-interest, in relation to, or
        in the event of, a merger, acquisition, sale of all or substantially all of our stock or
        assets, reorganization, bankruptcy, or other change of control. After such disclosure or
        transfer, the third party or successor in interest may use the information in accordance
        with applicable law.
      </p>
      <p>
        <strong>8. How Can You Access and Control Your Information?</strong>
      </p>
      <p>
        We may provide you with options to access and control your information. If applicable, we
        will provide instructions on these options on the Service. If such options are provided, we
        will take reasonable steps to help verify your identity before granting access, making
        corrections or removing your information in order to protect your privacy and security.
      </p>
      <p>
        <strong>9. How Do We Store and Protect Your Information?</strong>
      </p>
      <p>
        9.1. After receiving your PII, we will store it on our Service systems for future use. We
        have physical, electronic, and managerial procedures in place to safeguard and help prevent
        unauthorized access, maintain data security, and correctly use the information we collect.
        Unfortunately, no data transmission over the internet or data storage solution can ever be
        completely secure. As a result, although we take industry-standard steps to protect your
        information (e.g., strong encryption), we cannot ensure or warrant the security of any
        information you transmit to or receive from us or that we store on our or our service
        providers’ systems.
      </p>
      <p>
        9.2. If you are accessing the Service from outside of the United States of America (“USA”),
        you understand that your connection will be through and to servers located in the USA, and
        the information you provide will be securely stored in our web servers and internal systems
        located within the USA.
      </p>
      <p>
        9.3. We store your PII until we are required to remove it. We store our logs and other
        technical records in accordance with internal practices and policies, and potentially
        indefinitely.
      </p>
      <p>
        <strong>10. How Do We Use Cookies And Other Network Technologies?</strong>
      </p>
      <p>
        10.1. To enhance your online experience with us, our web pages may presently or in the
        future use “cookies.” Cookies are text files that our web server may place on your hard disk
        to store your preferences. We may use session, persistent, first-party and third-party
        cookies. Cookies, by themselves, do not tell us your email address or other PII unless you
        choose to provide this information to us. Once you choose to provide PII, however, this
        information may be linked to the data stored in the cookie.
      </p>
      <p>
        10.2. We or our service providers may also use “pixel tags,” “web beacons,” “clear GIFs”
        embedded links, and other commonly used information-gathering tools in connection with some
        Service pages and HTML-formatted email messages for such purposes as compiling aggregate
        statistics about Service usage and response rates. A pixel tag is an electronic image (often
        a single pixel), that is ordinarily not visible to website visitors, and may be associated
        with cookies on visitors’ hard drives. Pixel tags allow us and our service providers to
        count users who have visited certain pages of the Service, to deliver customized services,
        and to help determine the effectiveness of our Service and Service. When used in
        HTML-formatted email messages, pixel tags can inform the sender of the email whether and
        when the email has been opened.
      </p>
      <p>
        10.3. As you use the internet, you leave a trail of electronic information at each website
        you visit. This information, which is sometimes referred to as “clickstream data”, can be
        collected and stored by a website’s server. Clickstream data can reveal the type of computer
        and browsing software you use and the address of the website from which you linked to the
        Service. We may use clickstream data as a form of non-personally identifiable information to
        determine how much time visitors spend on each page of our Service, how visitors navigate
        through the Service, and how we may tailor our web pages to better meet the needs of
        visitors. We will only use this information to improve our Service.
      </p>
      <p>
        10.4. Do Not Track. At present, the Site does not specifically respond to browser
        do-not-track signals.
      </p>
      <p>
        <strong>11. Collection of Information by Others</strong>
      </p>
      <p>
        Our Terms of Service document identifies certain third-party websites to which we may
        provide links that you may click on through our Service. Please check the privacy policies
        of these other websites to learn how they collect, use, store and share information that you
        may submit to them or that they collect. This Privacy Policy only applies to our Service,
        not the services of any third party.
      </p>
      <p>
        <strong>12. EEA Privacy Rights</strong>
      </p>
      <p>
        12.1. If you currently reside in the United Kingdom, Northern Ireland, the European Union,
        or Switzerland (collectively, the “EEA”), under the European Union’s General Data Protection
        Regulation ("EU GDPR"), the United Kingdom’s General Data Protection Regulation ("UK GDPR"),
        or Switzerland’s privacy law (“Swiss GDPR” together with the EU GDPR and UK GDPR, as
        applicable the “GDPR”), you are a Data Subject and the GDPR applies to your PII which is
        considered Personal Data, as each are defined under the GDPR. We are the Controller with
        respect to PII that we collect directly from you (excluding Third Party Information) and the
        Processor with respect to Third Party Information, as each are defined under the GDPR. The
        GDPR requires that we, in our capacities as a Controller and/or Processor, have a legal
        basis to process your Personal Data. We process your Personal Data under one or more of the
        following legal bases:
      </p>
      <p>
        · Processing is necessary for our legitimate interests (or those of a third party) and your
        interests and fundamental rights do not override those interests;
      </p>
      <p>
        · To perform the contract that we are about to enter with you (e.g., our Terms of Service);
      </p>
      <p>· To comply with a legal obligation; and/or</p>
      <p>· If we have your consent to do so.</p>
      <p>12.2. Under the GDPR, as a Data Subject you have certain rights. They are:</p>
      <p>
        · The right to be informed. This is your right to be informed about what Personal Data they
        are processing, why, and who else the data may be passed to.
      </p>
      <p>
        · The right of access. This is your right to see what Personal Data about you is held by us.
      </p>
      <p>
        · The right to rectification. This is the right to have your Personal Data corrected or
        amended if what is held is incorrected in some way.
      </p>
      <p>
        · The right to erasure. This is the right to have your Personal Data to be deleted in the
        event that such data is no longer required for the purposes it was collected for, your
        consent for the processing of the data is withdrawn, or the data is being unlawfully
        processed.
      </p>
      <p>
        · The right to restrict processing. This is the right to ask for a temporary halt to
        processing of your Personal Data, such as in the case where a dispute or legal case has to
        be concluded, or the data is being corrected.
      </p>
      <p>
        · The right to data portability. This is the right to ask for your Personal Data to be
        provided to you in a structured, commonly used, and machine-readable format.
      </p>
      <p>
        · The right to object. This is the right to object to further processing your Personal Data
        if such processing is inconsistent with the primary purposes for which it was collected.
        This right may also be exercised by withdrawing your consent, which you may do at any time,
        if applicable.
      </p>
      <p>
        · Rights in relation to automated decision making and profiling. This is the right to not be
        subject to a decision based solely on automated processing. The service does not engage in
        automated decision making and profiling.
      </p>
      <p>
        12.3. You can find instructions for enforcing some of these rights elsewhere in this Privacy
        Policy. Otherwise, if you wish to find out more about these rights, please contact us at
        privacy@qoolo.com.
      </p>
      <p>
        <strong>13. California Privacy Rights</strong>
      </p>
      <p>
        13.1. If you are a resident of California, the California Consumer Privacy Act of 2018, as
        amended (“CCPA”), provides you with certain rights regarding your personal information. For
        purposes of the CCPA, we are a “Business,” you, if you are an individual residing in
        California, are a “Consumer,” and PII includes “Personal Information” as defined under the
        CCPA.
      </p>
      <p>
        13.2. Consumers have the right to request that we disclose Personal Information we have
        collected about them in the previous 12 months including, but not limited to, the categories
        of information collected by us, the source(s) of such information by category, and the
        purpose for collecting such information. This right may not be exercised more than twice in
        a 12-month period.
      </p>
      <p>
        In the previous 12 months, we have collected the following categories of Personal
        Information about Consumers:
      </p>
      <p>
        · Identifiers, examples of which include a Consumer’s name, mailing address, social security
        number, unique personal identifiers (device identifier, IP Address, cookies, beacons, pixel
        tags, mobile ad identifiers), account names, and similar information;
      </p>
      <p>
        · Commercial information, examples of which include records of personal property, products
        or services purchased, obtained, or considered, or other purchasing or consuming histories
        or tendencies;
      </p>
      <p>
        · Personal Records Under the California Consumer Records Law (Cal. Civ. Code §1798.80)
        (“CCRLPI”);
      </p>
      <p>
        · Internet/Network Activity, examples of which include browsing history, cookies, search
        history and a Consumer’s interaction with a website;
      </p>
      <p>· Geolocation data; and</p>
      <p>· Inferences drawn from any other category of personal information.</p>
      <p>
        We collect the Personal Information from our users and/or by automatic means. Such Personal
        Information is collected in order to provide the Service and its functionalities, to comply
        with applicable law, and as otherwise described above in this Privacy Policy.
      </p>
      <p>
        13.3. As a Consumer, you also have the right to request that we tell you which of your
        Personal Information we have disclosed for a business purpose, or sold, in the previous 12
        months, if any. In the past 12 months, we have disclosed Personal Information falling under
        the following categories of personal information:
      </p>
      <p>· Identifiers;</p>
      <p>· Commercial information;</p>
      <p>· CCRLPI;</p>
      <p>· Internet/Network Activity;</p>
      <p>· Geolocation data; and</p>
      <p>· Inferences.</p>
      <p>
        We disclose Personal Information in the preceding categories to the Consumers themselves, to
        third parties as the Consumer may direct, our service providers, and/or government/law
        enforcement agencies for the following purposes: the purposes for which it was provided for,
        the provision of the Service, in response to a Consumer’s specific request, to comply with
        applicable law, our legitimate internal business operations, and as otherwise described
        above in this Privacy Policy.
      </p>
      <p>
        IN THE PAST 12 MONTHS WE HAVE NOT SOLD, AND DURING THE PERIOD OF TIME WHICH THIS PRIVACY
        POLICY IS POSTED WE SHALL NOT SELL, THE PERSONAL INFORMATION OF ANY CONSUMER, OF ANY AGE.
      </p>
      <p>
        13.4. You also have the right to request the deletion of Personal Information that we have
        collected from you at any time. However, we may not be required to comply with such request
        under several circumstances including, but not limited to, when the data is necessary for
        the underlying transaction, to comply with applicable law, to detect security incidents, to
        debug glitches, and for our internal purposes.
      </p>
      <p>
        13.5. In the event that you exercise one of your rights under the CCPA, you will not be
        discriminated against by us in any way, whether it is through the denial of goods/services,
        providing you a different level of goods/services, or charging (or suggesting that we will
        charge) you different prices for the goods/services unless such change in price is
        reasonably related to the value you receive from your Personal Information.
      </p>
      <p>
        13.6. How do you exercise your rights under the CCPA? Because we offer the Service
        exclusively online, you may submit requests to exercise your rights under the CCPA by
        contacting us at legal@qoolo.com, please include “Request for Privacy Information” in the
        subject line.
      </p>
      <p>
        We will acknowledge receipt of your request within 10 days of receiving it, and use best
        efforts to respond within 45 days of receipt of your request, but in no event will our
        response come more than 90 days after your request. If we are unable to provide our response
        within the first 45 days following your request, we shall notify you as soon as we become
        aware of the possible delay and provide an explanation of why additional time is needed to
        respond.
      </p>
      <p>
        Before we respond to any CCPA based requests relating to your personal information, we may
        take steps to reasonably verify the identity of the person making the request to make sure
        it’s you, or your authorized agent (in either case, the “Requestor”). We do this to this
        avoid disclosing your information to third parties and bad actors, not to inconvenience you
        in any way. For purposes of verifying identity, we will ask the Requestor to confirm at
        least two pieces of information that we have in our files. If an agent is acting on behalf
        of the Consumer, we will also need to verify the agent’s identity and their authority to act
        on the Consumer’s behalf. As the sensitivity of the information being requested increases,
        we will ask the Requestor to provide more information to verify their identity and/or
        authority to make the request. If the identity of the Requestor cannot be reasonably
        verified, either as the Consumer or their agent, then in order to protect that Consumer, we
        may not disclose or delete the personal information that is the subject of the request.
      </p>
      <p>
        <strong>14. Children and Privacy</strong>
      </p>
      <p>
        We do not knowingly permit users to register for our Service if they are under 18 years old,
        and therefore do not request PII from anyone under the age of 13. If we become aware that a
        customer is under the age of 13 and has registered without prior verifiable parental
        consent, we will remove his or her PII from our files. If you are the parent or guardian of
        a person under the age of 13 who has provided PII to us without your approval, please inform
        us by contacting us at legal@qoolo.com and we will remove such information from our
        database.
      </p>
      <p>
        <strong>15. Contact Information</strong>
      </p>
      <p>
        If you have any questions or suggestions regarding our Privacy Policy, please contact us at
        legal@qoolo.com.
      </p>
    </>
  );
}

export default PrivacyPolicy;
