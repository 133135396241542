import React from "react";
import { useNavigate } from 'react-router-dom';

const DeveloperDashboard = () => {
  const navigate = useNavigate();
  
  const developerLinks = [
    {
      title: "Scan QR",
      path: "/developer/scan"
    },
    {
      title: "Cookie setup",
      path: "/developer/cookie"
    },
  ]
  return (
    <div style={{ padding: "20px", width: "100vw", height: "100vh" }}>
      <h3>Developer dashboard</h3>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", rowGap: "10px", columnGap: "10px" }}>
        {developerLinks.map((dl, idx) => <button
          key={idx}
          type="button"
          style={{
            width: "30vw",
            height: "10vh",
            margin: "10px",
            backgroundColor: "#eeeeee",
            border: "1px solid black",
            alignSelf: "center",
            fontSize: "18px",
          }}
          onClick={() => {
            navigate(dl.path);
          }}
        >
          {dl.title}
        </button>
        )}
      </div>
    </div>
  )
}

export default DeveloperDashboard;