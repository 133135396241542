import React from "react";
import { ReactComponent as LogoQC } from './icons/Logo.svg';

const Forbidden = () => {
  return (
    <div style={{textAlign: "center", paddingTop: "6rem"}}>
      <LogoQC className='opt-in__logo' style={{ color: "#4962E2", width: "140px" }} />
      <p style={{marginTop: "10rem", fontSize: "1.5rem"}}>
        403 Forbidden
      </p>
      <p style={{marginTop: "1rem", fontSize: "1rem"}}>
        Please contact a support
      </p>
    </div>
  )
}

export default Forbidden;