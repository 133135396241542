import React from "react";
import { ReactComponent as AlertIcon } from '../../icons/alert.svg';
import './AlertBox.css';

const AlertBox = (props) => {
  const { title, message, onClick } = props;
  return (
    <div className="alertbox-container">
      <section className="alertbox-modal">
        <header className="alertbox-modal-header">
          <AlertIcon />
          <div className="alertbox-modal-title">{title}</div>
        </header>
        <div className="alertbox-modal-content">
          {message}
        </div>
        <div className="alertbox-modal-footer">
          <button
            type="button"
            className="ant-btn ant-btn-primary ant-btn-block"
            onClick={() => {
              if(onClick) {
                onClick()
              }
            }}
          >
            <span>OK</span>
          </button>
        </div>
      </section>
    </div>
  )
}

export default AlertBox;