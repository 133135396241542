import React from 'react';
import { Button, Input } from 'antd';
import { ReactComponent as LogoQC } from '../icons/Logo.svg';
import { ReactComponent as CheckMark } from '../icons/ph_check-circle.svg';

const ConfirmPhoneVerification = ({
  studyName,
  deviceVerificationCode,
  verificationCodeInput,
  handleVerifyPhoneNumber,
  handleUpdatePhoneNumber
}) => {

  return (
    <>
      <div style={{ maxWidth: '350px', maxHeight: '800px', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div className='opt-in__logo-wrapper' style={{ backgroundColor: "#FFFFFF", paddingTop: "5vh" }}>
          <LogoQC style={{ color: "#4962E2", width: "203px", height: '70px', padding: 0 }} />
        </div>

        <div className='opt-in__body' style={{ height: "200px" }}>
          <h3 className='opt-in__title' style={{ fontFamily: 'Noto Sans', fontSize: '16px', fontWeight: 600 }}>
            Thank you for confirming that you are a part of the {studyName} clinical trial.
          </h3>

          <div className='opt-in__text' style={{ fontFamily: 'Noto Sans', fontSize: '16px', marginTop: '30px' }}>
            To continue, please enter your mobile phone number below to receive clinical trial notifications, including questionnaire notifications and clinical trial news. This is a requirement for participating in this clinical trial.
          </div>
        </div>

        <div className='opt-in__body' style={{ height: "240px", marginBottom: "5vh" }}>

          <div className='opt-in__form-item' style={{ fontFamily: 'Noto Sans', margin: 0, marginTop: '10px' }}>
            <Input
              type='text'
              placeholder='Enter Verification code'
              onChange={verificationCodeInput}
              style={{ fontFamily: 'Noto Sans', height: '60px', borderRadius: '10px', fontSize: '18px', textAlign: 'center' }}
            />
          </div>

          <div className='opt-in__form-item' style={{ fontFamily: 'Noto Sans', margin: 0, marginTop: '10px' }}>
            <Button
              block
              disabled={!deviceVerificationCode || deviceVerificationCode?.length < 6}
              type='primary'
              onClick={handleVerifyPhoneNumber}
              style={{ height: '60px', borderRadius: '10px', fontSize: '18px' }}
            >
              Confirm Code
            </Button>
          </div>

          <div className='opt-in__form-item' style={{ marginTop: '10px' }}>
            <div style={{ display: 'flex', fontFamily: 'Noto Sans', fontSize: '12px', height: '60px', border: '1px solid #39BB82', borderRadius: '10px', padding: '10px', alignItems: 'center', color: '#000000' }}>
              <CheckMark style={{ width: '40px', height: '40px', marginLeft: '10px', marginRight: '10px' }} />
              <span>We’ve sent you an SMS with the Verification code. If you haven’t received it, <span style={{ color: "#4962E2"}} onClick={() => handleUpdatePhoneNumber()}>try again</span>.</span>
            </div>
          </div>

        </div>
      </div>
    </>
  )

}

export default ConfirmPhoneVerification;