import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { EDIT_INNER_BLOCK_IN_LAYOUT } from '../../reducers/block-in-preview/actionTypes';

const ShapeElement = ({ block, width, dispatch }) => {
  const shapeRef = useRef();

  const [render, setRender] = useState(0);
  useEffect(() => {
    setRender(value => value + 1);
  }, []);

  const dimensions = shapeRef?.current?.getBoundingClientRect();
  let parser = new DOMParser();
  let doc = parser.parseFromString(block.blockProperties.initialShape, 'text/html');
  let shape = doc.body?.children[0];

  if (shape.tagName === 'POLYGON' && dimensions) {
    const points = shape.getAttribute('points').split(', ');
    const newPoints = points.map((point) => {
      let x = point.split(' ')[0];
      x = (dimensions.width / 100) * x;
      let y = point.split(' ')[1];
      y = (dimensions.height / 100) * y;
      return x + ' ' + y;
    }).join(', ');
    shape.setAttribute('points', newPoints);
  }
  const divContainer = document.createElement('div');
  divContainer.appendChild(shape);
  useEffect(() => {
    if (dispatch) {
      dispatch({
        type: EDIT_INNER_BLOCK_IN_LAYOUT,
        payload: {
          blockId: block?._id,
          updates: {
            clipPath: divContainer.innerHTML,
          }
        },
      });
    }
  }, [block?._id, dispatch, divContainer.innerHTML]);

  if (block.blockProperties.shapeColor) {
    shape.setAttribute('fill', block.blockProperties.shapeColor.hex);
    shape.setAttribute('fill-opacity', 1);
  } else {
    shape.setAttribute('fill-opacity', 0);
  }
  if (block.blockProperties.shapeBorderColor && block.blockProperties.shapeBorderThickness && block.blockProperties.shapeBorderType) {
    shape.setAttribute('stroke-width', block.blockProperties.shapeBorderThickness);
    shape.setAttribute('stroke', block.blockProperties.shapeBorderColor.hex);
    switch (block.blockProperties.shapeBorderType) {
      case 'dotted':
        shape.setAttribute('stroke-dasharray', '0 15');
        shape.setAttribute('stroke-linecap', 'round');
        break;
      case 'dashed':
        shape.setAttribute('stroke-dasharray', '30,13');
        shape.removeAttribute('stroke-linecap');
        break;
      default:
        shape.removeAttribute('stroke-dasharray');
        shape.removeAttribute('stroke-linecap');
        break;
    }
  } else if (block.blockProperties.shapeBorderThickness === 0 || block.blockProperties.shapeBorderType === 'none') {
    shape.removeAttribute('stroke');
  }
  doc.body.innerHTML = '';
  if (block.blockProperties.shapeHasShadow &&
    block.blockProperties.shapeInnerShadow) {
    shape.setAttribute('filter', 'url(#inset-shadow)');
  }
  doc.body.append(shape);

  let content = '';
  if (block.blockProperties.shapeHasShadow &&
    block.blockProperties.shapeInnerShadow) {
    content = `
     <defs>
    <filter id="inset-shadow">
      <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0 
        0 0 0 0 0 
        0 0 0 0 0 
        0 0 0 50 0" result="opaque-source"/>
      <feGaussianBlur stdDeviation="5"/>
      <feOffset dy="${block.blockProperties.shapeShadowY}"/>
      <feComposite operator="xor" in2="opaque-source"/>
      <feComposite operator="in" in2="opaque-source"/>
      <feComposite operator="over" in2="SourceGraphic"/>
    </filter>
  </defs>
  ${doc.body.innerHTML}
  `;
  } else {
    content = doc.body.innerHTML;
  }

  return (
    <div
      style={{
        fontSize: `${width / 570 * 16}px`,
        height: '100%',
        width: '100%',
        padding: '15px'
      }}
    >
      <svg
        height='100%'
        width='100%'
        overflow='visible'
        ref={shapeRef}
        style={{
          overflow: 'visible',
          filter:
            block.blockProperties.shapeHasShadow &&
              !block.blockProperties.shapeInnerShadow ? `drop-shadow(${block.blockProperties.shapeShadowX} ${block.blockProperties.shapeShadowY} ${block.blockProperties.shapeShadowBlur}  rgba(${block.blockProperties.shapeShadowColor.rgb.r}, ${block.blockProperties.shapeShadowColor.rgb.g}, ${block.blockProperties.shapeShadowColor.rgb.b}, ${block.blockProperties.shapeShadowOpacity}))` : 'unset'
        }}
        dangerouslySetInnerHTML={{ __html: content }}
      >
      </svg>
    </div>
  );
};

export default ShapeElement;