import React, { useState } from "react";
import { Button, Input } from 'antd';
import { ReactComponent as CheckedIcon } from '../icons/ph_check.svg';

const ListBox = ({ data, countryCode, setCountryCode, setSelectListCountryCode }) => {
  const [filteredData, setFilteredData] = useState(data);

  // console.log(data)
  const performSearch = (input) => {
    console.log(input)
    const filter = data.filter(f => {
      return f.label.toLowerCase().includes(input.toLowerCase());
    })
    if (input) {
      setFilteredData(filter)
    } else {
      setFilteredData(data)
    }
  }

  return (
    <div style={{
      height: '70vh',
      width: "100%",
      padding: "10px",
      border: "1px solid #d9d9d9",
      borderRadius: "10px",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>

      <div className='opt-in__form-group'>
        <Input
          type='text'
          placeholder='Search'
          onChange={(e) => performSearch(e.target.value)}
          style={{ fontFamily: 'Noto Sans', height: '40px', borderRadius: '10px', fontSize: '18px', textAlign: 'center' }}
        />
      </div>
      <div style={{ overflow: 'hidden', overflowY: 'scroll', marginTop: '10px', height: '100%', marginBottom: '10px', padding: 0 }}>
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
          {filteredData.map((d, i) => {
            return (
              <li key={i} style={{ display: 'flex', alignItems: 'center', height: '50px', borderBottom: '1px solid #d9d9d9' }} onClick={() => setCountryCode(d.value)}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ width: '80%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    {d.label}
                  </div>
                  <div>
                    {countryCode === d.value &&
                      <CheckedIcon
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                          color: '#4962E2'
                        }}
                      />
                    }
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <div className='opt-in__form-item' style={{ fontFamily: 'Noto Sans', margin: 0, marginTop: '10px' }}>
        <Button
          block
          type='primary'
          onClick={() => setSelectListCountryCode(false)}
          style={{ height: '60px', borderRadius: '10px', fontSize: '18px' }}
        >
          Select Country code
        </Button>
      </div>
    </div>
  )
}

export default ListBox;