import React from 'react';

function TermsOfService({ setPage }) {
  return (
    <div>
      <p>
        <strong>
          THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION AND WAIVER OF JURYTRIAL. PLEASE
          READ IT CAREFULLY BEFORE AGREEING.
        </strong>
      </p>
      <p>
        <strong>Terms of Service</strong>
      </p>
      <p>Last updated: March 30, 2023</p>
      <p>
        Qoolo, Inc. (hereinafter “Qoolo,” “we” or “us”) provides QClinical.io and QClinical.app
        (each a “Site” and together the “Sites”), and the associated data, services, information,
        tools, functionality, updates, and similar materials (collectively, the “Service”), subject
        to your agreement to and compliance with the conditions set forth in this Terms of Service
        agreement (the “Agreement”).
      </p>
      <p>
        This Agreement sets forth the legally binding terms and conditions governing your access to
        and use of the Service. By accessing or using the Service or otherwise entering into this
        Agreement, you are creating a binding contract with us. If you do not agree to these terms
        and conditions, you may not access or use the Service.
      </p>
      <p>
        We may revise or update this Agreement by posting an amended version through the Service and
        making you aware of the revisions, which may be through posting to the Service or otherwise.
        Your continued access to or use of the Service following our notice of changes to this
        Agreement (or other acceptance method) means you accept such changes. Please refer to the
        “Last updated” date above to see when the Agreement was last updated.
      </p>
      <p>
        <strong>1. Incorporated Terms</strong>
      </p>
      <p>
        The terms and conditions of our{' '}
        <span className='legal-link' onClick={() => setPage('privacyPolicy')}>
          Privacy Policy
        </span>{' '}
        (“Privacy Policy“) are incorporated as if fully recited herein. Please also refer to the
        subscription agreement executed by and between you, or the business that you represent, and
        us, if any (“Subscription Agreement”).
      </p>
      <p>
        <strong>2. Right to Access</strong>
      </p>
      <p>
        As long as you are in compliance with the conditions of this Agreement and all incorporated
        documents, we hereby grant you a limited, revocable, non-assignable, non-transferrable,
        non-sublicensable, non-exclusive right to access and use the Service for your internal
        business purposes only. No rights not explicitly listed herein are granted.
      </p>
      <p>
        <strong>3. User Eligibility</strong>
      </p>
      <p>
        Users on the Service may be businesses, or users given access to the Service by such
        businesses (collectively, “Users” or “you”). When used in this Agreement, “you” shall refer
        to the User individually, as well as in their capacity as an agent or representative of any
        business that such User represents, if any.
      </p>
      <p>
        Some parts of the Service may be intended for use solely by (i) employees or representatives
        of sponsors of clinical trials (“Sponsors”); (ii) employees or representatives of third
        parties that conduct clinical trial activities on behalf of Sponsors (“Clinical Sites”); or
        (iii) individual participants in a clinical trial (“End User” and together with the Sponsors
        and Clinical Sites, the “Users”); and as such, not all parts of the Service may be available
        to all Users or to the general public. We maintain the right to impose eligibility rules and
        limitation on User’s access to certain Services from time to time. We reserve the right to
        amend or eliminate these eligibility requirements at any time.
      </p>
      <p>
        By requesting to use, registering to use and/or using the Service, you represent and warrant
        that you (i) are at least eighteen (18) years old; (ii) have the right, authority, and
        capacity to enter into this Agreement and you commit to abide by all of the terms and
        conditions hereof; (iii) are properly licensed, permitted and qualified as required in your
        applicable jurisdiction to conduct yourself as a User; and (iv) are not a competitor of
        Qoolo.
      </p>
      <p>
        <strong>4. Important Notices</strong>
      </p>
      <p>
        We do not represent or warrant that access to the Service will be error-free or
        uninterrupted, or without defect, and we do not guarantee that Users will be able to access
        or use the Service, or its features, at all times.
      </p>
      <p>
        The Service may contain typographical errors or inaccuracies and may not be complete or
        current. We reserve the right to correct any such errors, inaccuracies, or omissions and to
        change or update information at any time without prior notice, even if your order has
        already been accepted or processed.
      </p>
      <p>
        We reserve the right at any time and from time to time to modify or discontinue, temporarily
        or permanently, the Service, or any part thereof, with or without notice.
      </p>
      <p>
        We do not provide medical or other healthcare services and are not licensed to practice
        medicine. We do not recommend or endorse any specific drugs, tests, healthcare providers,
        products, procedures, opinions, “off-label” drug uses or other information that may be
        mentioned on the Service, and we assume no responsibility for such information. Any
        information provided via the Service is for informational purposes only and does not
        constitute medical or any other professional diagnosis or treatment or suggest or propose a
        specific course of action. The Service is not intended to provide advice or guidance
        concerning the medical treatment or diagnosis of any person, nor is it intended as a
        substitute for the professional judgment of a healthcare professional when diagnosing or
        treating patients. End Users are solely responsible for their decision to use the Service,
        evaluating the information obtained through the Service, and for their decision to use such
        information in connection with treatment decisions and otherwise. End Users’ use of the
        information obtained through the Service is solely at their own risk, and End Users agree
        that we and our licensors are not responsible or liable for any claim, loss, or damage
        arising from the use of such information.
      </p>
      <p>
        The Service may be accessed on the premises, or using the systems, of Clinical Sites. We are
        not responsible for any conditions or defects on Clinical Site’s premises, the safety or
        security of Clinical Site’s premises or systems, or occurrences related to Clinical Site’s
        premises or systems.
      </p>
      <p>
        We do not credential, accredit or determine the qualifications of the practitioners,
        healthcare providers or facilities that may use the Service. We make no claim or
        representation regarding, and accept no responsibility for, the quality or reliability of
        any services or products provided by third parties using the Service. We do not endorse or
        warrant the accuracy or reliability of any advice, opinion, test results, statement or other
        information displayed, distributed or otherwise accessible on or through this Platform.
      </p>
      <p>
        We do not sponsor or provide regulatory or clinical services related to clinical trials
        facilitated by, or described on, the Service. We make no claim or representation regarding,
        and accept no responsibility for, any such clinical trials. We do not endorse or warrant the
        efficacy or appropriateness of any clinical trial facilitated by, or described on, the
        Service.
      </p>
      <p>
        THE SERVICE HAS NOT BEEN FDA APPROVED AS A MEDICAL DEVICE, AND IS NOT INTENDED TO BE USED
        FOR MEDICAL PURPOSES, INCLUDING USE IN THE DIAGNOSIS, MONITORING, TREATMENT, CURE OR
        PREVENTION OF DISEASE IN, OR STATES OF HEALTH OF, HUMANS OR OTHER ANIMALS. YOU MAY NOT, AND
        SHALL NOT PERMIT OTHERS, TO USE THE SERVICE IN CONNECTION WITH MEDICAL PURPOSES, INCLUDING
        USE IN THE DIAGNOSIS, MONITORING, TREATMENT, CURE OR PREVENTION OF DISEASE IN, OR
        PHYSIOLOGICAL STATUS OF, ANY HUMANS OR OTHER ANIMALS.
      </p>
      <p>
        <strong>5. Rules of Conduct</strong>
      </p>
      <p>
        Your access to and use of the Service is conditioned on your compliance with the terms of
        this Agreement, including but not limited to these rules of conduct.
      </p>
      <p>
        You agree not to distribute, upload, make available or otherwise publish through the Service
        any information, ideas, text, suggestions, comments, questions, messages, tags, causes,
        promotions, documents, links, or similar materials (“Submissions”) that:
      </p>
      <ul>
        <li>are unlawful or encourage another to engage in anything unlawful;</li>
        <li>
          contain a virus or any other similar programs or software which may damage the operation
          of our or another’s computer;
        </li>
        <li>
          violate the rights of any party or infringe upon the patent, trademark, trade secret,
          copyright, right of privacy or publicity or other intellectual property right of any
          party; or
        </li>
        <li>
          are libelous, defamatory, pornographic, obscene, lewd, indecent, inappropriate, invasive
          of privacy or publicity rights, abusing, harassing, threatening, or bullying.
        </li>
      </ul>
      <p>You further agree that you will not do any of the following:</p>
      <ul>
        <li>
          breach, through the Service, any agreements that you enter into with any third parties;
        </li>
        <li>stalk, harass, injure, or harm another individual through the Service;</li>
        <li>
          modify, adapt, translate, copy, reverse engineer, decompile or disassemble any portion of
          the Service;
        </li>
        <li>
          interfere with or disrupt the operation of the Service, including restricting or
          inhibiting any other person from using the Service by means of hacking or defacing;
        </li>
        <li>
          transmit to or make available in connection with the Service any denial of service attack,
          virus, worm, Trojan horse or other harmful code or activity;
        </li>
        <li>
          attempt to probe, scan or test the vulnerability of a system or network of the Service or
          to breach security or authentication measures without proper authorization;
        </li>
        <li>
          take any action that imposes, or may impose, in our sole discretion, an unreasonable or
          disproportionately large load on our infrastructure;
        </li>
        <li>
          harvest or collect the email address, contact information, or any other personal
          information of other users of the Service;
        </li>
        <li>
          use any means to crawl, scrape or collect content from the Service via automated or large
          group means;
        </li>
        <li>
          submit, post, or make available false, incomplete or misleading information to the
          Service, or otherwise provide such information to us;
        </li>
        <li>register for more than one user account;</li>
        <li>impersonate any other person or business;</li>
        <li>access or attempt to access any portion of the Service that is not public; or</li>
        <li>override or attempt to override any security measures in place on the Service.</li>
      </ul>
      <p>
        You must keep your username, password and any other information needed to log in to the
        Service, if applicable, confidential, and secure. We are not responsible for any
        unauthorized access to your account or profile by others.
      </p>
      <p>
        You agree that you will not violate any applicable law or regulation in connection with your
        use of the Service.
      </p>
      <p>
        Clinical Sites, and/or End Users, may be issued devices that facilitate transmission of
        Clinical Trial Data (as defined herein) from End Users to the Service (“Platform Assist
        Devices”). You agree that all Platform Assist Devices are owned by us and leased by Sponsor.
        Upon the completion of the applicable clinical trial or the expiration or termination of
        your use of the Service, you shall return the Platform Assist Devices to us or Sponsor on
        our behalf, or if you are an End User, to the applicable Clinical Trial Site on our behalf.
        You agree to reimburse us for (a) any unreturned Platform Assist Devices and (b) any
        Platform Assist Devices returned in destroyed or inoperable condition, in each case, in an
        amount equal to the full replacement cost of each such device.
      </p>
      <p>
        Notwithstanding anything herein to the contrary, we reserve the right, in our sole
        discretion, to protect Users from violators and violations of these rules of conduct,
        including but not limited to restricting your access to and use of the Service, restricting
        or terminating your ability to upload to the applicable website or platform, immediately
        terminating your access to the Service, or terminating your access to the Service by
        blocking certain IP addresses from accessing the Service. Notwithstanding the foregoing, our
        unlimited right to terminate your access to the Service shall not be limited to violations
        of these rules of conduct.
      </p>
      <p>
        <strong>6. Submissions and Clinical Trial Data Submitted or Made Available to Us</strong>
      </p>
      <p>
        Clinical Sites and End Users may submit or otherwise upload Submissions to the Service. In
        order for us to provide the Service, we need your permission to process, display, reproduce
        and otherwise use any Submissions, excluding Clinical Trial Data, you make available to us.
        By submitting or uploading a Submission to the Service, you hereby grant to us a perpetual,
        irrevocable, transferable, assignable, sub-licensable through multiple levels,
        non-exclusive, worldwide, royalty-free license to reproduce, use, modify, display, publish,
        transmit, distribute, and translate any such Submission. All Submissions made available to
        us will be held in alignment with our Privacy Policy.
      </p>
      <p>
        Submissions that consist of or include information or data relevant to a clinical trial, or
        that is solicited or collected on behalf of a Sponsor for a clinical trial, are “Clinical
        Trial Data”. Clinical Trial Data is the exclusive property of the Sponsor, and you hereby
        assign to Sponsor all right, title and interest in such Clinical Trial Data and all
        intellectual property rights thereto, or subsisting therein. All Clinical Trial Data
        uploaded to the Service will be used in accordance with the applicable Subscription
        Agreement.
      </p>
      <p>
        You acknowledge that we are under no obligation to maintain the Service, or any information,
        materials, Submissions, or other materials you submit, post, or make available to or on the
        Service. In addition, we do not pre-screen any Submissions or materials, but we reserve the
        right to refuse, withhold, remove and or discard any such material at any time.
      </p>
      <p>
        By submitting any Submissions to us you hereby agree, warrant and represent that: (a) the
        Submissions do not contain proprietary or confidential information, and the provision of the
        Submissions is not a violation of any applicable law or any third-party’s rights; (b) all
        such Submissions are complete, accurate and true, (c) we are not under any confidentiality
        obligation relating to the Submissions; (d) we shall be entitled to use or disclose the
        Submissions in any way; (e) you are not entitled to compensation or attribution from us in
        exchange for the Submissions; and (f) to the extent required, you have obtained all
        requisite consents and permissions for you and us to use the Submissions with respect to the
        Service and the Agreement.
      </p>
      <p>
        You understand that by sharing information or Submissions on the Service, by participating
        in the Service, and by requesting information to be sent through, or downloading information
        from, the Service, you may be revealing information about yourself, the entity that you
        represent, or your business that may include financial, credit, or similar information,
        including with other Users. You understand and acknowledge that you are fully aware and
        responsible for the impact of sharing such materials and using the Service, and you agree
        that we shall not be held responsible, and we shall be released and held harmless by you
        from any liability or damages arising out of such conduct.
      </p>
      <p>
        Certain Submissions submitted by Sponsors are governed by the terms and conditions of the
        Subscription Agreement.
      </p>
      <p>
        <strong>7. Intellectual Property</strong>
      </p>
      <p>
        Our graphics, logos, names, designs, page headers, button icons, scripts, and service names
        are our trademarks, trade names and/or trade dress. The “look” and “feel” of the Service
        (including color combinations, button shapes, layout, design, and all other graphical
        elements) are protected by U.S. copyright and trademark law. All product names, names of
        services, trademarks, and service marks (“Qoolo Marks”) are our property or the property of
        their respective owners, as indicated. You may not use the Qoolo Marks or copyrights for any
        purpose whatsoever other than as permitted by this Agreement.
      </p>
      <p>
        You acknowledge that the software used to provide the Service, and all enhancements,
        updates, upgrades, corrections and modifications to the software, all copyrights, patents,
        trade secrets, or trademarks or other intellectual property rights protecting or pertaining
        to any aspect of the software (or any enhancements, corrections, or modifications) and any
        and all documentation therefor, are and shall remain our sole and exclusive property or that
        of our licensors, as the case may be. This Agreement does not convey title or ownership to
        you, but instead gives you only the limited rights set forth herein.
      </p>
      <p>
        <strong>8. Data Collection and Use</strong>
      </p>
      <p>
        You understand and agree that our Privacy Policy shall govern the collection and use of data
        obtained by us through your access to and use of the Service.
      </p>
      <p>
        <strong>9. Enforcement and Termination</strong>
      </p>
      <p>
        We reserve the right to deny all or some portion of the Service to any User, in our sole
        discretion, at any time. Without limiting the foregoing or assuming additional legal
        obligations, we have a policy of terminating repeat violators of the Copyright Act, in
        accordance with applicable law. All grants of any rights from you to us related to
        Submissions or other materials, including but not limited to copyright licenses, shall
        survive any termination or expiration of this Agreement. Further, your representations,
        defense and indemnification obligations survive any termination or expiration of this
        Agreement.
      </p>
      <p>
        <strong>10. Links and Third-Party Content</strong>
      </p>
      <p>
        The Service may contain links to the sites or materials of third parties. Such links are
        provided for informational purposes only, and we do not endorse any website or services
        through the provision of such a link. The Service may contain articles, text, imagery,
        video, audio, data, information, and other similar materials originating from third parties.
        We do not endorse any third party content that may appear on the Service or that may be
        derived from content that may appear on the Service, even if such content was summarized,
        collected, reformatted, or otherwise edited by us.
      </p>
      <p>
        To the extent that you use any functionality of the Service to link to or submit third party
        Submissions or other materials or information, you shall obtain all requisite consents and
        permissions for you and us to use those materials with respect to the Service and the
        Agreement.
      </p>
      <p>
        <strong>11. DISCLAIMERS AND LIMITATION ON LIABILITY</strong>
      </p>
      <p>
        EXCEPT WHERE NOT PERMITTED BY LAW, YOU AGREE AND ACKNOWLEDGE THAT THE SERVICE IS PROVIDED
        “AS IS” AND “AS AVAILABLE”, WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR
        STATUTORY, AND WE, AND OUR PARENTS, SUBSIDIARIES, OFFICERS, DIRECTORS, SHAREHOLDERS,
        MEMBERS, MANAGERS, EMPLOYEES AND SUPPLIERS, SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF
        TITLE, ACCURACY, SUITABILITY, APPLICABILITY, MERCHANTABILITY, PERFORMANCE, FITNESS FOR A
        PARTICULAR PURPOSE, NON-INFRINGEMENT OR ANY OTHER WARRANTIES OF ANY KIND IN AND TO THE
        SERVICE. NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM US SHALL CREATE ANY
        WARRANTY.
      </p>
      <p>
        FURTHER, OPINIONS, ADVICE, STATEMENTS, OFFERS, SUBMISSIONS OR OTHER INFORMATION MADE
        AVAILABLE THROUGH THE SERVICE, BUT NOT DIRECTLY BY US, ARE THOSE OF THEIR RESPECTIVE
        AUTHORS, AND SHOULD NOT BE RELIED UPON. WE HAVE NO CONTROL OVER THE QUALITY, SAFETY OR
        LEGALITY OF SUCH CONTENT AND MAKE REPRESENTATIONS ABOUT SUCH CONTENT. THE RESPECTIVE AUTHORS
        ARE SOLELY RESPONSIBLE FOR SUCH CONTENT. YOU ARE SOLELY RESPONSIBLE FOR ANY DECISIONS THAT
        YOU MAKE BASED UPON SUCH CONTENT.
      </p>
      <p>
        ACCESS TO AND USE OF THE SERVICE IS AT YOUR SOLE RISK. WE DO NOT WARRANT THAT YOU WILL BE
        ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE
        SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; THAT DATA
        TRANSMISSION OR STORAGE IS SECURE OR THAT THE SERVICE IS FREE OF INACCURACIES,
        MISREPRESENTATIONS, VIRUSES OR OTHER HARMFUL INFORMATION OR COMPONENTS.
      </p>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, AND EXCEPT AS PROHIBITED BY LAW, IN NO EVENT SHALL
        WE OR OUR AFFILIATES, LICENSORS AND BUSINESS PARTNERS (COLLECTIVELY, THE “RELATED PARTIES”)
        BE LIABLE TO YOU BASED ON OR RELATED TO THE SERVICE, WHETHER BASED IN CONTRACT, TORT
        (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND SHALL NOT BE RESPONSIBLE FOR ANY
        LOSSES OR DAMAGES, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
        EXEMPLARY OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF
        THE SERVICE.
      </p>
      <p>
        Notwithstanding the foregoing, in the event that a court shall find that the above
        disclaimers are not enforceable, then, to the maximum extent permissible by law, you agree
        that neither we nor any of our subsidiaries, affiliated companies, employees, members,
        shareholders, officers or directors shall be liable for (1) any damages in excess of the
        greater of (a) $500.00 or (b) the amounts paid to, or by, you through the Service within the
        last six months, or (2) any indirect, incidental, punitive, special, or consequential
        damages or loss of use, lost revenue, lost profits or data to you or any third party from
        your use of the Service or any goods sold or provided by us. This limitation shall apply
        regardless of the basis of your claim, whether other provisions of this Agreement have been
        breached, or whether or not the limited remedies provided herein fail of their essential
        purpose.
      </p>
      <p>
        This limitation shall not apply to any damage that cannot be disclaimed in this Agreement.
        SOME STATES MAY NOT PERMIT CERTAIN DISCLAIMERS AND LIMITATIONS, AND ANY SUCH DISCLAIMERS OR
        LIMITATIONS ARE VOID WHERE PROHIBITED.
      </p>
      <p>
        <strong>12. Indemnification</strong>
      </p>
      <p>
        You agree to defend, indemnify and hold us and our affiliates, subsidiaries, suppliers,
        licensors, and licensees, and each of their officers, directors, shareholders, members,
        employees and agents harmless from all allegations, judgments, awards, losses, liabilities,
        costs and expenses, including but not limited to reasonable attorney’s fees, expert witness
        fees, and costs of litigation (“Costs”) arising out of or based on: (a) Submissions you
        submit, link to, post to or transmit through the Service; (b) your access to or use of the
        Service; (c) your violation of this Agreement; (d) any conduct, activity or action which is
        unlawful or illegal under any state, federal or common law, or is violative of the rights of
        any individual or entity, engaged in, caused by, or facilitated in any way through the
        access to or use of the Service; (e) allegations that Submissions you submit, link to, post
        to or transmit through the Service infringes the intellectual property rights of, or
        violates other rights of, a third party; or (f) in the case of Clinical Sites, any injury or
        damage alleged to have been suffered as a result of End Users‘ use or consumption or the
        Clinical Sites’ administration of any materials, products or devices as a part of the
        services that you provide (g) any injuries or damages related to Clinical Sites’ or End
        Users’ use of the Platform Assist Devices (h) with respect to Clinical Sites, any
        allegations of bodily injury, death of any person, or damage to real or tangible, personal
        property occurring during the performance by Qoolo of any services on-site on such Clinical
        Sites’ premises. In the case of subsection (e), Costs shall include but not be limited to,
        any of our expenses related to responding to or complying with third party notices or
        demands.
      </p>
      <p>
        <strong>13. Governing Law and Jurisdiction; Arbitration</strong>
      </p>
      <p>
        You agree that any claim or dispute arising out of or relating in any way to the Service
        will be resolved solely and exclusively by binding arbitration, rather than in court, except
        that you may assert claims in small claims court if your claims qualify. The Federal
        Arbitration Act and federal arbitration law apply to this agreement. The laws of the State
        of New York shall govern this Agreement and shall be used in any arbitration proceeding.
      </p>
      <p>
        There is no judge or jury in arbitration, and court review of an arbitration award is
        limited. However, an arbitrator can award on an individual basis the same damages and relief
        as a court (including injunctive and declaratory relief or statutory damages) and must
        follow the terms of this Agreement as a court would.
      </p>
      <p>
        To begin an arbitration proceeding, you must send an email requesting arbitration and
        describing your claim to legal@qoolo.com.
      </p>
      <p>
        Arbitration under this Agreement will be conducted by the American Arbitration Association
        (AAA) under its rules then in effect, shall be conducted in English, and shall be located in
        New York, New York. Payment of all filing, administration and arbitrator fees will be
        governed by the AAA's rules.
      </p>
      <p>
        You and Qoolo agree that any dispute resolution proceedings will be conducted only on an
        individual basis and not in a class, consolidated or representative action. If for any
        reason a claim proceeds in court rather than in arbitration, both you and Qoolo agree that
        each have waived any right to a jury trial.
      </p>
      <p>
        Notwithstanding the foregoing, you agree that we may bring suit in court to enjoin
        infringement or other misuse of intellectual property or other proprietary rights.
      </p>
      <p>
        All aspects of the arbitration proceeding, and any ruling, decision, or award by the
        arbitrator, will be strictly confidential for the benefit of all parties.
      </p>
      <p>
        To the extent arbitrations does not apply, you agree that any dispute arising out of or
        relating to the Service, or to us, may only be brought by you in a state or federal court
        located in New York, New York. YOU HEREBY WAIVE ANY OBJECTION TO THIS VENUE AS INCONVENIENT
        OR INAPPROPRIATE AND AGREE TO EXCLUSIVE JURISDICTION AND VENUE IN NEW YORK.
      </p>
      <p>
        <strong>14. Policies for Children</strong>
      </p>
      <p>
        The Service is not directed to individuals under the age of eighteen. In the event that we
        discover that a child under the age of 18 has provided personally identifiable information
        to us, we will make efforts to delete the child’s information if required by the Children's
        Online Privacy Protection Act. Please see the Federal Trade Commission's website for
        (www.ftc.gov) for more information. For more information about our privacy practices with
        respect to the personal information of children, please see our Privacy Policy.
      </p>
      <p>
        Notwithstanding the foregoing, pursuant to 47 U.S.C. Section 230 (d), as amended, we hereby
        notify you that parental control protections are commercially available to assist you in
        limiting access to material that is harmful to minors. More information on the availability
        of such software can be found through publicly available sources. You may wish to contact
        your internet service provider for more information.
      </p>
      <p>
        <strong>15. General</strong>
      </p>
      <p>
        <u>Severability.</u> If any provision of this Agreement is found for any reason to be
        unlawful, void or unenforceable, then that provision will be given its maximum enforceable
        effect or shall be deemed severable from this Agreement and will not affect the validity and
        enforceability of any remaining provision.
      </p>
      <p>
        <u>Revisions.</u> This Agreement is subject to change on a prospective basis at any time. In
        the event that we change this Agreement, you may be required to re-affirm the Agreement
        through use of the Service or otherwise. Your access to and use of the Service after the
        effective date of any changes will constitute your acceptance of such changes.
      </p>
      <p>
        <u>No Partnership.</u> You agree that no joint venture, partnership, employment, or agency
        relationship exists between you and us as a result of this Agreement or your use of the
        Service.
      </p>
      <p>
        <u>Assignment.</u> We may assign our rights under this Agreement, in whole or in part, to
        any person or entity at any time with or without your consent. You may not assign the
        Agreement without our prior written consent, and any unauthorized assignment by you shall be
        null and void.
      </p>
      <p>
        <u>No Waiver.</u> ur failure to enforce any provision of this Agreement shall in no way be
        construed to be a present or future waiver of such provision, nor in any way affect the
        right of any party to enforce each and every such provision thereafter. The express waiver
        by us of any provision, condition or requirement of this Agreement shall not constitute a
        waiver of any future obligation to comply with such provision, condition, or requirement.
      </p>
      <p>
        <u>Notices.</u> All notices given by you or required under this Agreement shall be in
        writing and sent via email to legal@qoolo.com.
      </p>
      <p>
        <u>Equitable Remedies.</u> You hereby agree that we would be irreparably damaged if the
        terms of this Agreement were not specifically enforced, and therefore you agree that we
        shall be entitled, without bond, other security, or proof of damages, to appropriate
        equitable remedies with respect to breaches of this Agreement, in addition to such other
        remedies as we may otherwise have available to us under applicable laws.
      </p>
      <p>
        <u>Entire Agreement.</u> This Agreement, including the documents expressly incorporated by
        reference, constitutes the entire agreement between you and us with respect to the Service,
        and supersedes all prior or contemporaneous communications, whether electronic, oral, or
        written.
      </p>
      <p>
        <u>Force Majeure.</u> In no event shall we or our affiliates be liable to you for any
        damage, delay, or failure to perform resulting directly or indirectly from any event outside
        of our reasonable control or any force majeure event.
      </p>
      <p>
        <strong>16. Copyright Policy</strong>
      </p>
      <p>
        If you believe in good faith that any material posted on our Service infringes the copyright
        in your work, please contact our copyright agent, designated under the Digital Millennium
        Copyright Act (“DMCA”) (17 U.S.C. §512(c)(3)), with correspondence containing the following:
      </p>
      <p>
        · A physical or electronic signature of the owner, or a person authorized to act on behalf
        of the owner, of the copyright that is allegedly infringed;
      </p>
      <p>· Identification of the copyrighted work claimed to have been infringed;</p>
      <p>
        · Identification, with information reasonably sufficient to allow its location of the
        material that is claimed to be infringing;
      </p>
      <p>· Information reasonably sufficient to permit us to contact you;</p>
      <p>
        · A statement that you have a good faith belief that use of the material in the manner
        complained of is not authorized by the copyright owner, its agent, or the law; and
      </p>
      <p>
        · A statement that the information in the notification is accurate, and under penalty of
        perjury, that you are authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed.
      </p>
      <p>
        You acknowledge that if you fail to comply with all of the requirements of this policy, your
        DMCA notice may not be valid. For any questions regarding this procedure, or to submit a
        complaint, please contact our designated DMCA Copyright Agent:
      </p>
      <p>
        Copyright Agent
        <br /> Qoolo, Inc.
        <br /> 174 Nassau Street #424
        <br /> Princeton, NJ 08542
        <br /> e-mail: legal@qoolo.com
      </p>
      <p>
        <strong>17. Complaint Policy (Including Trademark and Privacy)</strong>
      </p>
      <p>
        If you believe in good faith that any material posted on the Service infringes any of your
        rights other than in copyright, or is otherwise unlawful, you must send a notice to
        legal@qoolo.com containing the following information:
      </p>
      <p>· Your name, physical address, e-mail address and phone number;</p>
      <p>
        · A description of the material posted on the Sites that you believe violates your rights or
        is otherwise unlawful, and which parts of said materials you believe should be remedied or
        removed;
      </p>
      <p>
        · Identification of the location of the material on the Sites; · If you believe that the
        material violates your rights, a statement as to the basis of the rights that you claim are
        violated;
      </p>
      <p>
        · If you believe that the material is unlawful or violates the rights of others, a statement
        as to the basis of this belief;
      </p>
      <p>
        · A statement under penalty of perjury that you have a good faith belief that use of the
        material in the manner complained of is not authorized and that the information you are
        providing is accurate to the best of your knowledge and in good faith; and
      </p>
      <p>· Your physical or electronic signature.</p>
      <p>
        If we receive a message that complies with all of these requirements, we will evaluate the
        submission, and if appropriate, in our sole discretion, we will take action. We may disclose
        your submission to the poster of the claimed violative material, or any other party.
      </p>
    </div>
  );
}

export default TermsOfService;
