import React from 'react';
import { Button } from 'antd';
import { ReactComponent as LogoQC } from '../icons/Logo.svg';
import { ReactComponent as XMark } from '../icons/ph_x-circle.svg';

const NotFound = () => {
  return (
    <>
      <div style={{ maxWidth: '350px', maxHeight: '800px', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div className='opt-in__logo-wrapper' style={{ backgroundColor: "#FFFFFF", paddingTop: "5vh" }}>
          <LogoQC style={{ color: "#4962E2", width: "203px", height: '70px', padding: 0 }} />
        </div>

        <div className='opt-in__body' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "200px" }}>
          <div className='opt-in__text' style={{ fontFamily: 'Noto Sans', fontSize: '16px', fontWeight: 400, marginTop: '30px' }}>
            If you a participant in a QClinical study, please scan the QR Code that you received during onboarding to reactivate your participation in a QClinical study, or reach out too our Support Team.
          </div>
        </div>

        <div className='opt-in__body' style={{ height: "240px", marginBottom: "5vh" }}>


        </div>
      </div>
    </>
  )
}

export default NotFound;