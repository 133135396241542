import React, { useEffect, useState } from "react";
import QrReader from 'react-qr-scanner'
import { useNavigate } from 'react-router-dom';

const DeveloperScan = () => {
  const navigate = useNavigate();
  const [scanner, setScanner] = useState(false)
  const [scanOk, setScanOk] = useState(false)
  const [localStorageData, setLocalStorageData] = useState();

  const handleScan = (data) => {
    if (data && data.text) {
      if (data.text.includes('qclinical')) {
        setScanOk(true)
        window.location.replace(data.text);
      }
    }
  }
  const handleError = (err) => {
    console.error(err)
  }

  const previewStyle = {
    width: '320px',
    height: '240px',
    margin: "1rem",
    border: scanOk ? "3px solid green" : "3px solid grey"
  }

  useEffect(() => {
    setLocalStorageData(localStorage.getItem('sample_data'));
  }, [])

  const getData = () => {
    setLocalStorageData(localStorage.getItem('sample_data'));
  }

  const setData = () => {
    localStorage.setItem('sample_data', new Date().toLocaleString());
  }

  return (
    <div style={{ textAlign: "center" }}>
      <img src="/Logo.svg" style={{ marginTop: "5rem" }} />
      <div style={{ marginTop: "2rem", textAlign: "center" }}>
        <p>Scan your QR</p>
        {!scanner &&
          <div style={{ width: "100%", height: '240px', marginTop: "10rem" }}>
            <button
              type="button"
              style={{
                width: "100px",
                height: "100px",
                border: "1px solid blue",
                borderRadius: "50px"
              }}
              onClick={() => {
                setScanner(true)
              }}>
              Scan
            </button>
          </div>
        }

        {scanner &&
          <QrReader
            delay={500}
            style={previewStyle}
            onError={handleError}
            onScan={handleScan}
            facingMode='environment'
          />
        }

      </div>
      <div style={{ width: "100%" }}>
        <button type="button" style={{ width: "100px", border: "1px solid grey", borderRadius: "10px", margin: "5px" }} onClick={setData}>SET DATA</button>
        <button type="button" style={{ width: "100px", border: "1px solid grey", borderRadius: "10px", margin: "5px" }} onClick={getData}>GET DATA</button>
        <p>Data: {localStorageData}</p>
      </div>
      <button
        type="button"
        style={{
          width: "50vw",
          height: "5vh",
          marginTop: "50px",
          backgroundColor: "#eeeeee",
          border: "1px solid black",
          alignSelf: "center",
          fontSize: "18px",
        }}
        onClick={() => {
          navigate('/developer')
        }}
      >
        Developer dashboard
      </button>
    </div>
  )
}

export default DeveloperScan;