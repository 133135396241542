import React, { useContext } from 'react';
import { Image } from 'antd';
// import { icons } from '../../icons/menu-icons/menuIcons';
import { icons } from '../../icons/menu-icons/menuIcons';
// import viewTypes from '../../params/viewTypes';
import viewTypes from '../../params/viewTypes';

const QooHeader = ({
  headerHeight,
  header,
  gridWidth,
  gridRowHeight,
  fadeOutHF,
  viewType,
  onMenuClickCallback,
}) => {
  if (!headerHeight) return null;

  let isMenuEnabled = false;

  const onMenuClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onMenuClickCallback) {
      onMenuClickCallback();
    }
  };

  if (viewType === viewTypes.published.key) {
    // Menu is enabled only if viewType is of type 'published'.
    isMenuEnabled = true;
  }

  return (
    <div
      style={{
        width: '100%',
        height: `${headerHeight * gridRowHeight}px`,
        position: 'absolute',
        left: '0',
        top: '0',
        fontSize: gridWidth ? `${(gridWidth / 384) * 16}px` : '16px',
        opacity: fadeOutHF ? '0.3' : '1',
      }}
    >
      {header.layout.topHeader && (
        <div
          style={{
            fontSize: '0.875em',
            width: '100%',
            height: '33%',
            background: header.topHeaderColor
              ? `rgba(${header.topHeaderColor.rgb.r}, ${header.topHeaderColor.rgb.g}, ${header.topHeaderColor.rgb.b}, ${header.topHeaderColor.rgb.a})`
              : '',
            borderBottom: header.topHeaderBorderColor
              ? `1px solid ${header.topHeaderBorderColor.hex}`
              : '',
            fontFamily: header.topHeaderFont ? header.topHeaderFont.name : '',
            fontStyle: header.topHeaderFont ? header.topHeaderFont.style : '',
            fontWeight: header.topHeaderFont ? header.topHeaderFont.weight : '',
            color: header.topHeaderTextColor
              ? header.topHeaderTextColor.hex
              : '',
            boxShadow: header.topHeaderBoxShadow
              ? '0px 4px 4px rgba(0, 0, 0, 0.1)'
              : '',
            display: 'flex',
            alignItems: 'center',
            padding: '0.0625em 1em',
          }}
        >
          {header.topHeaderColumnsNum && header.topHeaderColumnsNum > 0 && (
            <div
              style={{
                flex: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: header.topHeaderTextAlign,
              }}
            >
              {header.topHeaderColumn1TextContent}
            </div>
          )}
          {header.topHeaderColumnsNum && header.topHeaderColumnsNum > 1 && (
            <div
              style={{
                flex: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: header.topHeaderTextAlign,
              }}
            >
              {header.topHeaderColumn2TextContent}
            </div>
          )}
        </div>
      )}
      <div
        style={{
          width: '100%',
          height: header.layout.topHeader ? '67%' : '100%',
          background: header.backgroundColor
            ? `rgba(${header.backgroundColor.rgb.r}, ${header.backgroundColor.rgb.g}, ${header.backgroundColor.rgb.b}, ${header.backgroundColor.rgb.a})`
            : '#BDC7CF',
          borderBottom: header.borderColor
            ? `1px solid ${header.borderColor.hex}`
            : '',
          boxShadow: header.dropshadow ? '0px 4px 4px rgba(0, 0, 0, 0.1)' : '',
          // fontSize: small ? '9px' : '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: header.layout.justifyContent,
          padding: '1em 1.125em',
        }}
      >
        {header.layout.slot1 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.75em',
              flex: '1',
              justifyContent: 'flex-start',
            }}
          >
            {header.layout.slot1.map((item, index) => {
              if (item.type === 'logo' && header.components.includes('logo')) {
                if (header.logo) {
                  return (
                    <Image
                      key={item + index + 1500}
                      preview={false}
                      src={header.logo}
                      style={{
                        maxWidth: '6em',
                        height: 'auto',
                        maxHeight: '2em',
                      }}
                    />
                  );
                } else {
                  return (
                    <h2
                      key={item + index + 1500}
                      style={{
                        fontFamily: 'Titilium Web',
                        fontSize: '1.5em',
                        margin: 0,
                      }}
                    >
                      LOGO
                    </h2>
                  );
                }
              } else if (item.type === 'text') {
                if (header.textContent) {
                  return (
                    <span
                      key={item + index + 1500}
                      style={{
                        fontFamily: header.textFont ? header.textFont.name : '',
                        fontStyle: header.textFont ? header.textFont.style : '',
                        fontWeight: header.textFont
                          ? header.textFont.weight
                          : '',
                        fontSize: header.textFontSize
                          ? header.textFontSize === 'L'
                            ? '1.5em'
                            : header.textFontSize === 'S'
                            ? '0.75em'
                            : '1em'
                          : '1em',
                        color: header.textColor ? header.textColor.hex : '',
                        textAlign: header.textTextAlign,
                      }}
                    >
                      {header.textContent}
                    </span>
                  );
                } else {
                  return <span key={item + index + 1500}>Text</span>;
                }
              } else if (
                item.type === 'menu' &&
                header.components.includes('menu')
              ) {
                return (
                  <div
                    key={item + index + 1500}
                    style={{
                      color: header.menuIconColor
                        ? header.menuIconColor.hex
                        : '',
                      width: '1.5em',
                      height: '1.5em',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={isMenuEnabled ? onMenuClick : null}
                  >
                    {icons[`${header.menuIcon}`]}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
        {header.layout.slot2 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.75em',
              flex: '1',
              justifyContent: 'center',
            }}
          >
            {header.layout.slot2.map((item, index) => {
              if (item.type === 'logo' && header.components.includes('logo')) {
                if (header.logo) {
                  return (
                    <Image
                      key={item + index + 300}
                      preview={false}
                      src={header.logo}
                      style={{
                        width: '6em',
                        height: 'auto',
                        maxHeight: '2em',
                      }}
                    />
                  );
                } else {
                  return (
                    <h2
                      key={item + index + 300}
                      style={{
                        fontFamily: 'Titilium Web',
                        fontSize: '1.5em',
                        margin: 0,
                      }}
                    >
                      LOGO
                    </h2>
                  );
                }
              } else if (item.type === 'text') {
                if (header.textContent) {
                  return (
                    <span
                      key={item + index + 300}
                      style={{
                        fontFamily: header.textFont ? header.textFont.name : '',
                        fontStyle: header.textFont ? header.textFont.style : '',
                        fontWeight: header.textFont
                          ? header.textFont.weight
                          : '',
                        fontSize: header.textFontSize
                          ? header.textFontSize === 'L'
                            ? '1.5em'
                            : header.textFontSize === 'S'
                            ? '0.75em'
                            : '1em'
                          : '1em',
                        color: header.textColor ? header.textColor.hex : '',
                        textAlign: header.textTextAlign,
                      }}
                    >
                      {header.textContent}
                    </span>
                  );
                } else {
                  return <span key={item + index + 300}>Text</span>;
                }
              } else if (
                item.type === 'menu' &&
                header.components.includes('menu')
              ) {
                return (
                  <div
                    key={item + index + 300}
                    style={{
                      color: header.menuIconColor
                        ? header.menuIconColor.hex
                        : '',
                      width: '1.5em',
                      height: '1.5em',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={isMenuEnabled ? onMenuClick : null}
                  >
                    {icons[`${header.menuIcon}`]}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
        {header.layout.slot3 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.75em',
              flex: '1',
              justifyContent: 'flex-end',
            }}
          >
            {header.layout.slot3.map((item, index) => {
              if (item.type === 'logo' && header.components.includes('logo')) {
                if (header.logo) {
                  return (
                    <Image
                      key={item + index + 600}
                      preview={false}
                      src={header.logo}
                      style={{
                        width: '6em',
                        height: 'auto',
                        maxHeight: '2em',
                      }}
                    />
                  );
                } else {
                  return (
                    <h2
                      key={item + index + 600}
                      style={{
                        fontFamily: 'Titilium Web',
                        fontSize: '1.5em',
                        margin: 0,
                      }}
                    >
                      LOGO
                    </h2>
                  );
                }
              } else if (item.type === 'text') {
                if (header.textContent) {
                  return (
                    <span
                      key={item + index + 600}
                      style={{
                        fontFamily: header.textFont ? header.textFont.name : '',
                        fontStyle: header.textFont ? header.textFont.style : '',
                        fontWeight: header.textFont
                          ? header.textFont.weight
                          : '',
                        fontSize: header.textFontSize
                          ? header.textFontSize === 'L'
                            ? '1.5em'
                            : header.textFontSize === 'S'
                            ? '0.75em'
                            : '1em'
                          : '1em',
                        color: header.textColor ? header.textColor.hex : '',
                        textAlign: header.textTextAlign,
                      }}
                    >
                      {header.textContent}
                    </span>
                  );
                } else {
                  return <span key={item + index + 600}>Text</span>;
                }
              } else if (
                item.type === 'menu' &&
                header.components.includes('menu')
              ) {
                return (
                  <div
                    className='menu-icon'
                    key={item + index + 600}
                    style={{
                      color: header.menuIconColor
                        ? header.menuIconColor.hex
                        : '',
                      width: '1.5em',
                      height: '1.5em',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={isMenuEnabled ? onMenuClick : null}
                  >
                    {icons[`${header.menuIcon}`]}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default QooHeader;
