import React, { useContext, useEffect, useState } from 'react';
import FormElement from '../blocks/FormElement';
import MediaElement from '../blocks/MediaElement'; // OMITTED
import ShapeElement from '../blocks/ShapeElement';
import SurveyElement from '../blocks/SurveyElement';
import _ from 'lodash';
import buttonActionTypes from '../../params/blocks/buttonActionTypes';
import { openUrlInNewTab, removeUrlProtocolFromUrl } from '../../utils/common';
import viewTypes from '../../params/viewTypes';
import Axios from 'axios';
import { verificationGroup, qaGroup, commonGroup, forwardGroup } from '../../params/blocks/blockEventTypeGroups'
import SERVER_URL from '../../params/config'
import Loading from './Loading';
import { ViewerContext } from '../../App';
import AlertBox from '../../components/elements/AlertBox';

const QooBlocksLayout = ({
  layout,
  gridWidth,
  gridRowHeight,
  viewType,
  onButtonClickCallback,
  qoolectionCookie,
  qooInPreview,
  qoolectionId,
  setRefetchQoolection,
  setLoading
}) => {
  // Button click is enabled only if viewType is of type 'published'.
  const isButtonClickEnabled = viewType === viewTypes.published.key;
  const [localStorageState, setLocalStorageState] = useState(JSON.parse(localStorage.getItem('qoolectionData')));
  const [refetchLocalStorage, setRefetchLocalStorage] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { statusOnline, pwaAppend } = useContext(ViewerContext);

  useEffect(() => {
    setLocalStorageState(JSON.parse(localStorage.getItem('qoolectionData')))
  }, [refetchLocalStorage])

  const onClick = (layoutItem, e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    const blockEvents = _.get(
      layoutItem,
      'block.blockEvents',
      null
    );

    performEvents(blockEvents, layoutItem);
    setRefetchLocalStorage(rlc => !rlc);
  };

  const performEvents = async (blockEvents, layoutItem) => {
    // Get qoolection data from localStorage
    let localStorageData = JSON.parse(localStorage.getItem('qoolectionData'));

    // Check if localStorage data exists. If not, create it
    let data = {};
    if (localStorageData) {
      data = localStorageData;
    } else {
      data = {
        qoolection: qoolectionId,
        patientToken: qoolectionCookie,
        eventTime: Date.now(),
        timeZoneOffset: new Date().getTimezoneOffset(),
        qoolectionData: []
      };
    }

    let verificationEventsGroup = [];
    let qaEventsGroup = [];
    let commonEventsGroup = [];
    let forwardEventsGroup = [];
    for (const bEvent of blockEvents) {
      if (verificationGroup.includes(bEvent.eventType)) {
        verificationEventsGroup.push(bEvent);
      }
      if (qaGroup.includes(bEvent.eventType)) {
        qaEventsGroup.push(bEvent);
      }
      if (commonGroup.includes(bEvent.eventType)) {
        commonEventsGroup.push(bEvent);
      }
      if (forwardGroup.includes(bEvent.eventType)) {
        forwardEventsGroup.push(bEvent);
      }
    }

    let allVerified = true;

    if (verificationEventsGroup.length > 0) {
      for (const e of verificationEventsGroup) {
        switch (e.eventType) {
          case 'VerifyQuestion':
            if (data && data.qoolectionData) {
              let allQoolectionData = data.qoolectionData;
              let numberOfAnswers = allQoolectionData.filter(aqd => aqd.question._id === e.question._id).length;

              if (numberOfAnswers < e.question.minSelect) {
                allVerified = false;
                setAlertMessage(`"${e.question.question}" needs min. ${e.question.minSelect} selected option!`)
                setShowAlert(true);
                setLoading(false);
                return;
              }
            }
            break;
        }
      }
    }
    if (qaEventsGroup.length > 0) {
      for (const e of qaEventsGroup) {
        switch (e.eventType) {
          case 'SelectOption':
            if (data && data.qoolectionData) {
              let allQoolectionData = data.qoolectionData;

              // Collect all question answers from localStorage
              const answersByQuestion = allQoolectionData.filter(aqd => aqd.question._id === e.question._id);

              let currentEventDataIndex = allQoolectionData.findIndex(aqd => (aqd.question._id === e.question._id && aqd.answer === e.answer));
              let data2Write = {
                qoo: qooInPreview?._id,
                ...e,
                eventTime: Date.now()
              }
              if (currentEventDataIndex < 0) {
                // Check if maxSelect is 1
                if (e.question.maxSelect === 1) {
                  // Loop through all existed answers and remove it
                  answersByQuestion.forEach(abq => {
                    let tempIndex = allQoolectionData.findIndex(aqd => (aqd.question._id === abq.question._id && aqd.answer === abq.answer));
                    allQoolectionData.splice(tempIndex, 1);
                  });
                  // Add current answer
                  data.qoolectionData.push(data2Write)
                } else {
                  // q.maxSelect is more than 1
                  if (allQoolectionData.filter(aqd => aqd.question._id === e.question._id).length < e.question.maxSelect) {
                    data.qoolectionData.push(data2Write)
                  }
                }
              } else {
                allQoolectionData.splice(currentEventDataIndex, 1);
              }

              data.qoolectionData = allQoolectionData;
              localStorage.setItem('qoolectionData', JSON.stringify(data))
            }
            break;
          case 'SubmitData':
            if (allVerified) {
              await sendData(`${SERVER_URL}/patients-study-epro-data`, {
                qoo: qooInPreview?._id,
                block: layoutItem?.block?._id,
                qoolection: qoolectionId,
                patientToken: qoolectionCookie,
                ...e,
                answer: data.qoolectionData,
                eventTime: Date.now(),
                timeZoneOffset: new Date().getTimezoneOffset()
              })

              // Clear localStorage
              localStorage.removeItem('qoolectionData');

              // TODO: proveriti sa Danilom zasto imamo ovaj drugi request bez qoolectionData
              await sendData(`${SERVER_URL}/patient-study-events`, {
                qoo: qooInPreview?._id,
                block: layoutItem?.block?._id,
                qoolection: qoolectionId,
                patientToken: qoolectionCookie,
                ...e,
                eventTime: Date.now(),
                timeZoneOffset: new Date().getTimezoneOffset()
              })
            }
            break;

          default:
            await sendData(`${SERVER_URL}/patients-study-data`, {
              qoo: qooInPreview?._id,
              block: layoutItem?.block?._id,
              qoolection: qoolectionId,
              patientToken: qoolectionCookie,
              ...e,
              eventTime: Date.now(),
              timeZoneOffset: new Date().getTimezoneOffset()
            })
            break;
        }
      }
    }
    if (commonEventsGroup.length > 0) {
      if (allVerified) {
        for (const e of commonEventsGroup) {
          switch (e.eventType) {
            case 'ChangeInitialQoo':
              localStorage.setItem('qc_start_qoo', e.setInitialQooInQoolection);
          }
          await sendData(`${SERVER_URL}/patient-study-events`, {
            qoo: qooInPreview?._id,
            block: layoutItem?.block?._id,
            qoolection: qoolectionId,
            patientToken: qoolectionCookie,
            ...e,
            eventTime: Date.now(),
            timeZoneOffset: new Date().getTimezoneOffset(),
          })
          // setRefetchQoolection(r => !r)
        }
      }
    }
    if (forwardEventsGroup.length > 0) {
      const e = forwardEventsGroup[0];  // Possible ONLY one link action!
      if (allVerified) {
        switch (e.eventType) {
          case 'InternalLink':
            setTimeout(() => {
              onButtonClickCallback(e.internalLinkToQoo);
            }, (e.linkDelay || 0) * 500)
            break;
          case 'ExternalLink':
            window.open(e.externalLinkUrl, '_self');
            break;
          case 'RefreshApp':
            setTimeout(() => {
              window.location.reload();
            }, e.refreshAppDelay || 500)
            break;
          case 'CloseApp':    // ToDo: Cannot be performed, try other way or use internal link to some final Qoo
            window.close();
            break;
          default:
            break;
        }
      }
    }

    setLoading(false);
  }

  const sendData = async (url, data) => {
    try {
      await Axios.post(
        url,
        data,
        {}
      );
    } catch (error) {     // ToDo: PWA
      console.log(error);
      // if(error.code === "ERR_NETWORK") {
      // }
      pwaAppend(url, data);
      if (error.response?.status == 403) {
        window.open('/forbidden', '_self')
      }
    }
  };

  const checkBlockEventAnswerExists = (block) => {
    // Get blockEvents from the current block
    let events = block.blockEvents || [];

    // Get qoolection data from localStorage
    let exists = false;
    if (localStorageState) {
      let allQoolectionData = localStorageState.qoolectionData;
      for (const bEvent of events) {
        let currentEventDataIndex = allQoolectionData.findIndex(aqd => (aqd.question?._id === bEvent.question?._id && aqd.answer === bEvent.answer));
        if (currentEventDataIndex >= 0) {
          exists = true;
        }
      }
    }

    return exists;
  }

  return (
    <>
      {/* <Loading isLoading={loading} /> */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(12, ${gridRowHeight}px)`,
          gridTemplateRows: `repeat(22, ${gridRowHeight}px)`,
        }}
      >
        {showAlert &&
          <AlertBox
            title="Alert"
            message={alertMessage}
            onClick={() => {
              setShowAlert(false)
            }}
          />
        }

        {layout.map((item) => {
          const block = item.block;

          return (
            <div
              key={block?._id}
              style={{
                gridColumn: `${item.x + 1} / span ${item.w}`,
                gridRow: `${item.y + 1} / span ${item.h}`,
                fontSize: `${(gridWidth / 576) * 16}px`,
                paddingTop:
                  (gridWidth / 576) * block?.blockProperties?.blockPaddingTop,
                paddingRight:
                  (gridWidth / 576) * block?.blockProperties?.blockPaddingRight,
                paddingBottom:
                  (gridWidth / 576) * block?.blockProperties?.blockPaddingBottom,
                paddingLeft:
                  (gridWidth / 576) * block?.blockProperties?.blockPaddingLeft,
                display: block?.isMulti ? 'grid' : 'block',
                gridTemplateColumns: block?.isMulti
                  ? `repeat(${block.size[1]}, ${gridRowHeight}px)`
                  : '',
                gridTemplateRows: block?.isMulti
                  ? `repeat(${block.size[0]}, ${gridRowHeight}px)`
                  : '',
                backgroundColor: block?.blockProperties?.blockBackgroundImage
                  ? ''
                  : block?.blockProperties?.blockBackgroundColor
                    ? block?.blockProperties?.blockBackgroundColor.hex
                    : block?.theme?.colorAssignment?.blockBackground
                      ? block?.theme.colorAssignment?.blockBackground.hex
                      : 'transparent',
                backgroundImage: block?.blockProperties?.blockBackgroundImage
                  ? `url(${block?.blockProperties?.blockBackgroundImage})`
                  : '',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                border:
                  block?.blockProperties?.blockBorderType &&
                    block?.blockProperties?.blockBorderColor &&
                    block?.blockProperties?.blockBorderThickness
                    ? `${block.blockProperties.blockBorderThickness}em ${block.blockProperties.blockBorderType} ${block.blockProperties.blockBorderColor.hex}`
                    : 'none',
              }}
            >
              {block?.isMulti && block?.blocksLayout ? (
                <>
                  {block.blocksLayout.map((item) => {
                    return (
                      <div
                        key={item.block._id}
                        style={{
                          gridColumn: `${item.x + 1} / span ${item.w}`,
                          gridRow: `${item.y + 1} / span ${item.h}`,
                          paddingTop:
                            (gridWidth / 576) *
                            item.block.blockProperties.textPaddingTop,
                          paddingRight:
                            (gridWidth / 576) *
                            item.block.blockProperties.textPaddingRight,
                          paddingBottom:
                            (gridWidth / 576) *
                            item.block.blockProperties.textPaddingBottom,
                          paddingLeft:
                            (gridWidth / 576) *
                            item.block.blockProperties.textPaddingLeft,

                          fontSize: `${(gridWidth / 576) * 16}px`,
                          border:
                            item.block.blockProperties.iconBorderType &&
                              item.block.blockProperties.iconBorderThickness &&
                              item.block.blockProperties.iconBorderColor
                              ? `${item.block.blockProperties.iconBorderThickness}em ${item.block.blockProperties.iconBorderType} ${item.block.blockProperties.iconBorderColor.hex}`
                              : 'none',
                          backgroundColor: item.block.blockProperties
                            .textBackgroundImage
                            ? ''
                            : item.block.blockProperties.iconBackgroundColor?.hex,
                        }}
                      >
                        {item?.block?.blockType?.name === 'text' && (
                          <div
                            className='text-content-container'
                            dangerouslySetInnerHTML={{
                              __html: item.block.blockProperties.textContent,
                            }}
                            style={{
                              height: '100%',
                              fontSize: `${(gridWidth / 576) * 16}px`,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              lineHeight: '1.2',
                            }}
                          ></div>
                        )}
                        {item?.block?.blockType?.name === 'button' && (
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              lineHeight: '1.2'
                            }}
                          >
                            <div
                              style={{
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: `${item.block.blockProperties.buttonWidth || 75
                                  }%`,
                                height: `${item.block.blockProperties.buttonHeight || 75
                                  }%`,
                                backgroundColor:
                                  item.block.blockProperties.buttonBackgroundColor
                                    ?.hex,
                                color: item.block.blockProperties.buttonTextColor,
                                border:
                                  item.block.blockProperties
                                    .buttonBorderThickness &&
                                    item.block.blockProperties.buttonBorderType &&
                                    item.block.blockProperties.buttonBorderColor
                                    ? `${item.block.blockProperties.buttonBorderThickness} ${item.block.blockProperties.buttonBorderType} ${item.block.blockProperties.buttonBorderColor.hex}`
                                    : 'none',
                                borderRadius:
                                  item.block.blockProperties.buttonBorderRadius,
                                boxShadow: item.block.blockProperties
                                  .buttonHasShadow
                                  ? `
                            ${item.block.blockProperties.buttonInnerShadow
                                    ? 'inset'
                                    : ''
                                  }
                            ${item.block.blockProperties.buttonShadowX} 
                            ${item.block.blockProperties.buttonShadowY}
                            ${item.block.blockProperties.buttonShadowBlur}
                            rgba(${item.block.blockProperties.buttonShadowColor.rgb.r
                                  }, ${item.block.blockProperties.buttonShadowColor
                                    .rgb.g
                                  }, ${item.block.blockProperties.buttonShadowColor
                                    .rgb.b
                                  }, ${item.block.blockProperties
                                    .buttonShadowOpacity
                                  })
                          `
                                  : '',
                              }}
                              onClick={
                                isButtonClickEnabled
                                  ? onClick.bind(this, item)
                                  : null
                              }
                            >
                              <div
                                className='text-content-container'
                                dangerouslySetInnerHTML={{
                                  __html: item.block.blockProperties.buttonText,
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                        {item?.block?.blockType?.name === 'divider' && (
                          <div className='divider-container'>
                            <div
                              className='divider'
                              style={{
                                width: `${item.block.blockProperties.dividerLength}%`,
                                borderBottom:
                                  item.block.blockProperties.dividerThickness &&
                                    item.block.blockProperties.dividerType &&
                                    item.block.blockProperties.dividerColor
                                    ? `${item.block.blockProperties.dividerThickness}em ${item.block.blockProperties.dividerType} ${item.block.blockProperties.dividerColor.hex}`
                                    : 'none',
                                // alignSelf: item.block.blockProperties.dividerAlignPosition === 'center' ? 'center' : (item.block.blockProperties.dividerAlignPosition === 'top' ? 'flex-start' : 'flex-end')
                              }}
                            ></div>
                          </div>
                        )}
                        {item?.block?.blockType?.name === 'icon' && (
                          <div
                            style={{
                              height: '100%',
                              backgroundColor: item.block.blockProperties
                                .iconColor
                                ? item.block.blockProperties.iconColor.hex
                                : '#2A3A49',
                              mask: `url(${item.block.blockProperties.iconSrc})`,
                              maskRepeat: 'no-repeat',
                              maskPosition: 'center center',
                              maskSize: `${item.block.blockProperties.iconSize}%`,
                              WebkitMask: `url(${item.block.blockProperties.iconSrc})`,
                              WebkitMaskRepeat: 'no-repeat',
                              WebkitMaskPosition: 'center center',
                              WebkitMaskSize: `${item.block.blockProperties.iconSize}%`,
                              position: 'relative',
                              // zIndex: '-1'
                            }}
                          ></div>
                        )}
                        {item?.block?.blockType?.name === 'form' && (
                          <FormElement block={item.block} width={gridWidth} />
                        )}
                        {item?.block?.blockType?.name === 'survey' && (
                          <SurveyElement block={item.block} width={gridWidth} />
                        )}
                        {/* OMITTED - BEGIN */}
                        {item?.block?.blockType?.name === 'media' && (
                          <MediaElement block={item.block} width={gridWidth} />
                        )}
                        {/* OMITTED - END */}
                        {item?.block?.blockType?.name === 'shape' && (
                          <ShapeElement block={item.block} width={gridWidth} />
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div
                  style={{
                    height: '100%',
                    backgroundColor:
                      item?.block?.blockType?.name === 'icon'
                        ? item.block.blockProperties.iconBackgroundColor?.hex
                        : '',
                    border:
                      item?.block?.blockType?.name === 'icon' &&
                        item?.block?.blockProperties?.iconBorderThickness &&
                        item?.block?.blockProperties?.iconBorderType &&
                        item?.block?.blockProperties?.iconBorderColor
                        ? `${item.block.blockProperties.iconBorderThickness}em ${item.block.blockProperties.iconBorderType} ${item.block.blockProperties.iconBorderColor.hex}`
                        : '',
                    paddingTop:
                      (gridWidth / 576) *
                      item?.block?.blockProperties?.textPaddingTop,
                    paddingRight:
                      (gridWidth / 576) *
                      item?.block?.blockProperties?.textPaddingRight,
                    paddingBottom:
                      (gridWidth / 576) *
                      item?.block?.blockProperties?.textPaddingBottom,
                    paddingLeft:
                      (gridWidth / 576) *
                      item?.block?.blockProperties?.textPaddingLeft,
                  }}
                >
                  {item?.block?.blockType?.name === 'text' && (
                    <div
                      className='text-content-container'
                      dangerouslySetInnerHTML={{
                        __html: item?.block?.blockProperties?.textContent,
                      }}
                      style={{
                        height: '100%',
                        fontSize: `${(gridWidth / 576) * 16}px`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        lineHeight: '1.2',
                      }}
                    ></div>
                  )}
                  {item?.block?.blockType?.name === 'button' && (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        lineHeight: '1.2',
                      }}
                    >
                      <div
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: `${item.block.blockProperties.buttonWidth || 75
                            }%`,
                          height: `${item.block.blockProperties.buttonHeight || 75
                            }%`,
                          backgroundColor:
                            item.block.blockProperties.buttonBackgroundColor?.hex,
                          color: item.block.blockProperties.buttonTextColor,
                          border: checkBlockEventAnswerExists(block) ? "3px solid black" :
                            (item.block.blockProperties.buttonBorderThickness &&
                              item.block.blockProperties.buttonBorderType &&
                              item.block.blockProperties.buttonBorderColor
                              ? `${item.block.blockProperties.buttonBorderThickness} ${item.block.blockProperties.buttonBorderType} ${item.block.blockProperties.buttonBorderColor.hex}`
                              : 'none'),
                          borderRadius:
                            item.block.blockProperties.buttonBorderRadius,
                          boxShadow: item.block.blockProperties.buttonHasShadow
                            ? `
                            ${item.block.blockProperties.buttonInnerShadow
                              ? 'inset'
                              : ''
                            }
                            ${item.block.blockProperties.buttonShadowX} 
                            ${item.block.blockProperties.buttonShadowY}
                            ${item.block.blockProperties.buttonShadowBlur}
                            rgba(${item.block.blockProperties.buttonShadowColor.rgb.r
                            }, ${item.block.blockProperties.buttonShadowColor.rgb.g
                            }, ${item.block.blockProperties.buttonShadowColor.rgb.b
                            }, ${item.block.blockProperties.buttonShadowOpacity
                            })
                          `
                            : '',
                        }}
                        onClick={
                          isButtonClickEnabled
                            ? onClick.bind(this, item)
                            : null
                        }
                      >
                        <div
                          className='text-content-container'
                          dangerouslySetInnerHTML={{
                            __html: item.block.blockProperties.buttonText,
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                  {item?.block?.blockType?.name === 'divider' && (
                    <div className='divider-container'>
                      <div
                        className='divider'
                        style={{
                          width: `${item.block.blockProperties.dividerLength}%`,
                          borderBottom:
                            item.block.blockProperties.dividerThickness &&
                              item.block.blockProperties.dividerType &&
                              item.block.blockProperties.dividerColor
                              ? `${item.block.blockProperties.dividerThickness}em ${item.block.blockProperties.dividerType} ${item.block.blockProperties.dividerColor.hex}`
                              : 'none',
                          // alignSelf: item.block.blockProperties.dividerAlignPosition === 'center' ? 'center' : (item.block.blockProperties.dividerAlignPosition === 'top' ? 'flex-start' : 'flex-end')
                        }}
                      ></div>
                    </div>
                  )}
                  {item?.block?.blockType?.name === 'icon' && (
                    <div
                      style={{
                        height: '100%',
                        backgroundColor: item.block.blockProperties.iconColor
                          ? item.block.blockProperties.iconColor.hex
                          : '#2A3A49',
                        mask: `url(${item.block.blockProperties.iconSrc})`,
                        maskRepeat: 'no-repeat',
                        maskPosition: 'center center',
                        maskSize: `${item.block.blockProperties.iconSize}%`,
                        WebkitMask: `url(${item.block.blockProperties.iconSrc})`,
                        WebkitMaskRepeat: 'no-repeat',
                        WebkitMaskPosition: 'center center',
                        WebkitMaskSize: `${item.block.blockProperties.iconSize}%`,
                        position: 'relative',
                        // zIndex: '-1'
                      }}
                    ></div>
                  )}
                  {item?.block?.blockType?.name === 'form' && (
                    <FormElement block={item.block} width={gridWidth} />
                  )}
                  {item?.block?.blockType?.name === 'survey' && (
                    <SurveyElement block={item.block} width={gridWidth} />
                  )}
                  {/* OMITTED - BEGIN */}
                  {item?.block?.blockType?.name === 'media' && (
                    <MediaElement block={item.block} width={gridWidth} />
                  )}
                  {/* OMITTED - END */}
                  {item?.block?.blockType?.name === 'shape' && (
                    <ShapeElement block={item.block} width={gridWidth} />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QooBlocksLayout;
