/**
 * Opens url in a new tab with optional timeout.
 * @param url (required)
 * @param timeout (optional, in milliseconds)
 * @returns {boolean}
 */
export const openUrlInNewTab = (url, timeout = 0) => {
  if (!url) {
    return false;
  }

  setTimeout(() => {
    const win = window.open(`https://${url}`, '_blank');
    win.focus();
    return true;
  }, timeout);
};

/**
 *
 * @param url {string}
 * @returns {string}
 */
export const removeUrlProtocolFromUrl = (url) => {
  return url.replace('https://', '').replace('http://', '');
};